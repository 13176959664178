import React, { useEffect, useState } from 'react'
import DashboardTiles from './dashboard/dashboardTiles'
import { capitalizeFirstLetter } from '../../components/usefullFuntions'
import { chartData1, chartData2, chartData3, chartData4 } from '../../data/dashboardTilesChartData'
import { networkRequest } from '../../utils/network_request'
import { apiAdmin, apiExecutive } from '../../utils/urls'
import { useGlobalState } from '../../global_state/GlobalStateContext'
import MixedChart from '../../components/mixCharts'

const Dashboard = () => {
  const { state, dispatch } = useGlobalState();
  const [transactions, setTransactions] = useState(null);
  const [dashboardData, setDashboardData] = useState({
    users: "0",
    clients: "0",
    executive: "0",
    coins: "0"
  });
  const [graphData, setGraphData] = useState({
    "executives": [],
    "clients": [],
    "users": []
  });

  const isLoading = (status) => {
    dispatch({ type: 'SET_LOADING', payload: status });
  }

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = () => {
    var url = apiAdmin.dashboard;
    if (localStorage.getItem('user_type') === 'executive')
      url = apiExecutive.dashboard;

    networkRequest(url, (data) => {
      setDashboardData({
        ...dashboardData,
        executive: data['data']['executives'],
        clients: data['data']['clients'],
        users: data['data']['users'],
        coins: (data['data']['coins'] !== null) ? data['data']['coins']['total'] : 0
      });
      setGraphData(data['data']['graph']);
      setTransactions(data['data']['transactions']);
    }, isLoading)
  }

  return (
    <div className="ms-content-wrapper m-2">
      <div className="row">
        <div className="col-md-12">
          <h1 className="db-header-title">Welcome, {capitalizeFirstLetter(localStorage.getItem('name'))}</h1>
        </div>
        <div className="col-12">
          <div className="row">
            <DashboardTiles title="Total Registered Candidates" value={dashboardData.users} chartData={chartData2} />
            <DashboardTiles title="Total Registered Client" value={dashboardData.clients} chartData={chartData3} />
            <DashboardTiles title="Total Number Of Executives" value={dashboardData.executive} chartData={chartData4} />
            <DashboardTiles title="Coins" value={dashboardData.coins} chartData={chartData1} />
            <DashboardTiles title="Total Job Posts" value={dashboardData.users} chartData={chartData2} />
            <DashboardTiles title="Pending Posts" value={dashboardData.clients} chartData={chartData3} />
            <DashboardTiles title="Rejected Post" value={dashboardData.executive} chartData={chartData4} />
            <DashboardTiles title="Highest Applicants post" value={dashboardData.coins} chartData={chartData1} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Registration Progress Graph</h6>
            </div>
            <div className="ms-panel-body">
              <MixedChart graphData={graphData} />
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Registration Progress Graph</h6>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Trns Id</th>
                      <th>User Name</th>
                      <th scope="col">Coins</th>
                      <th scope="col">Balance</th>
                      <th scope="col">Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions && (transactions.map((trans) => (
                      <tr key={trans.id}>
                        <td>{trans.id.padStart(5, '0')}</td>
                        <td>{trans.name}</td>
                        <td>{trans.coins}</td>
                        <td>{trans.total}</td>
                        <td>{trans.coins >= 0 ? 'Credit' : 'Debit'}</td>
                      </tr>
                    )))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
