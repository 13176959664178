import React, { useState } from 'react';

const ChangePassword = () => {
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleInput = (e) => {
        setFormData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    const validateForm = () => {
        const newErrors = {};

        ['oldPassword', 'newPassword', 'confirmPassword'].forEach((field) => {
            if (!formData[field] || formData[field].length < 6) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required and must be at least 6 characters.`;
            }
        });

        if (formData.newPassword === formData.oldPassword) {
            newErrors.newPassword = 'New password and Old Password should not be the same.';
        }

        if (formData.newPassword !== formData.confirmPassword) {
            newErrors.confirmPassword = 'New password and Confirm Password do not match.';
        }

        setErrors(newErrors);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        validateForm();
        console.log(formData);
        // Additional logic for form submission
    };

    return (
        <div className="ms-content-wrapper">
            <div className="row">
                <div className="col-md-12 m-3">
                    <div className="ms-panel">
                        <div className="ms-panel-header">
                            <h6>Change Password</h6>
                        </div>
                        <div className="ms-panel-body w-50">
                            <form onSubmit={handleSubmit}>
                                {['oldPassword', 'newPassword', 'confirmPassword'].map((field) => (
                                    <div className="form-group" key={field}>
                                        <label htmlFor={`example${field.charAt(0).toUpperCase() + field.slice(1)}`}>{`${field.charAt(0).toUpperCase() + field.slice(1)}`}</label>
                                        <input type="password" className="form-control" id={`example${field.charAt(0).toUpperCase() + field.slice(1)}`} placeholder={field.charAt(0).toUpperCase() + field.slice(1)} name={field} value={formData[field]} onChange={handleInput} />
                                        {submitted && errors[field] && (<div className="text-danger"> {errors[field]}</div>)}
                                    </div>
                                ))}
                                <input type="submit" className="btn btn-primary d-block w-50" name="btn50" defaultValue="Medium" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
