import React, { useCallback, useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import customerImg from '../../../assets/img/avatar.png'
import { useGlobalState } from '../../../global_state/GlobalStateContext';
import { apiAdmin, imageUrls } from '../../../utils/urls';
import { networkRequest } from '../../../utils/network_request';
import { domain } from '../../../utils/domain';
import Swal from 'sweetalert2';

const ExecutiveProfile = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state, dispatch } = useGlobalState();
    const [userData, setUserData] = useState(null);
    const [isActive, setIsActive] = useState(false);

    const isLoading = useCallback((status) => {
        dispatch({ type: 'SET_LOADING', payload: status });
    }, [dispatch]);

    useEffect(() => {
        networkRequest(`${apiAdmin.executiveGetProfile}/${id}`, setData, isLoading);
    }, [id, isLoading]);

    const setData = (data) => {
        setUserData(data['data']);
        setIsActive(data['data'] ? (data['data']['status']['is_active'] === "1") ? true : false : false);
    }

    const handleActiveInactive = () => {
        var id = userData['status']['id'];
        var status = (isActive) ? "0" : "1";
        console.log(state);
        networkRequest(`${apiAdmin.executiveActiveInactive}/${id}/${status}`, (resp) => {
            Swal.fire({
                title: 'Success',
                text: (!isActive) ? "Activated" : "Inactive",
                icon: 'success',
                type: 'success'
            });
            setIsActive(!isActive);
        }, isLoading);
    }

    return (
        <div className="ms-content-wrapper">
            <div className="ms-profile-overview">
                <div className="ms-profile-cover">
                    <img className="ms-profile-img" src={userData ? `${domain}/assets/executive/${userData['profile']['passport_size_photo']}` : customerImg} alt="people" />
                    <div className="ms-profile-user-info">
                        <h4 className="ms-profile-username text-white">{userData ? userData['profile']['name'] : "loading ..."}</h4>
                        <h2 className="ms-profile-role">Executive</h2>
                    </div>
                    <div className="ms-profile-user-buttons">
                        <button className="btn btn-primary mr-1" onClick={(e) => {
                            navigate(-1);
                        }}> <i className="fa fa-arrow-circle-left" aria-hidden="true"></i> Back</button>
                        <Link to={`/admin/transfer_balance/${userData ? userData['profile']['credential_id'] : 0}`} className="btn btn-primary"> <i className="material-icons">add</i> Add Coins</Link>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-xl-5 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body">
                            <ul className="ms-profile-stats">
                                <li>
                                    <h3 className="ms-count">{userData ? userData['userRefer'] : 0}</h3>
                                    <span>Users</span>
                                </li>
                                <li>
                                    <h3 className="ms-count">{userData ? userData['clientRefer'] : 0}</h3>
                                    <span>Clients</span>
                                </li>
                                <li>
                                    <h3 className="ms-count">{userData && userData['coins'] && userData['coins'][0] ? userData['coins'][0]['total'] : 0}</h3>
                                    <span>Coins</span>
                                </li>
                            </ul>
                            <h2 className="section-title">Executive Profile <Link to={`/admin/executive/edit/${id}`}> (Edit <i className="fas fa-pencil-alt text-secondary" />)</Link></h2>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Executive Status (<span className={(isActive) ? "text-success" : "text-danger"}> {(isActive) ? "Active" : "Inactive"} </span>)</th>
                                        <td>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={isActive} onChange={handleActiveInactive} />
                                                <span className="ms-switch-slider ms-switch-success round" />
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Executive Registration Id</th>
                                        <td> #{userData ? userData['profile']['id'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Full Name</th>
                                        <td>{userData ? userData['profile']['name'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Birthday</th>
                                        <td>{userData ? userData['profile']['date_of_birth'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Contact</th>
                                        <td>{userData ? userData['profile']['contact'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Email</th>
                                        <td>{userData ? userData['profile']['email'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">City State</th>
                                        <td style={{ whiteSpace: 'pre-line' }}>{userData ? userData['profile']['city'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Address</th>
                                        <td style={{ whiteSpace: 'pre-line' }}>{userData ? userData['profile']['address'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Id Proof</th>
                                        <td> {userData && userData['profile']['id_proof_link'] && (<a target='_blank' rel="noreferrer" href={`${imageUrls.executive}${userData['profile']['id_proof_link']}`}>
                                            <i className="fa fa-eye" aria-hidden="true"> View </i>
                                        </a>
                                        )} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Address Proof</th>
                                        <td> {userData && userData['profile']['address_proof_link'] && (<a target='_blank' rel="noreferrer" href={`${imageUrls.executive}${userData['profile']['address_proof_link']}`}>
                                            <i className="fa fa-eye" aria-hidden="true"> View </i>
                                        </a>
                                        )} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Reset Password</th>
                                        <td>
                                            <button type="button" name="" id="" className="btn btn-primary"><i className="fa fa-key" aria-hidden="true"></i> Reset Password</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-xl-7 col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body">
                            <Tab.Container defaultActiveKey="tab1">
                                <Nav variant="tabs" className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1">Performance Chart</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2">Client Added</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab3">User Added</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab4">Transactions</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="tab1">
                                        {/* <LineChartScreen></LineChartScreen> */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <div className="table-responsive">
                                            <table className="table table-hover thead-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Contact</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Date Of Birth</th>
                                                        <th scope="col">Address</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab3">
                                        <div className="table-responsive">
                                            <table className="table table-hover thead-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Contact</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Date Of Birth</th>
                                                        <th scope="col">Address</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab4">
                                        <div className="table-responsive">
                                            <table className="table table-hover thead-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Transaction Id</th>
                                                        <th scope="col">Coins</th>
                                                        <th scope="col">Balance</th>
                                                        <th scope="col">Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userData && userData['coins'] && (userData['coins'].map((coin) => (
                                                        <tr key={coin.id}>
                                                            <td>{coin.id.padStart(5, '0')}</td>
                                                            <td>{coin.coins}</td>
                                                            <td>{coin.total}</td>
                                                            <td>{coin.coins >= 0 ? 'Credit' : 'Debit'}</td>
                                                        </tr>
                                                    )))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <p>Transactions</p>
                                        userData['coins'] */}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ExecutiveProfile;
