import React, { useEffect, useRef, useState } from 'react';
import "datatables.net-bs4/js/dataTables.bootstrap4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import $ from 'jquery';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import { networkRequest } from '../../utils/network_request';
import { apiAdmin } from '../../utils/urls';
import { Link } from 'react-router-dom';

const Posts = () => {
  const [postList, setPostList] = useState([]);
  const [filter, setFilter] = useState('All'); // State for filter value
  const { state, dispatch } = useGlobalState();

  const isLoading = (state) => {
    dispatch({ type: 'SET_LOADING', payload: state });
  }

  const loadData = () => {
    networkRequest(apiAdmin.getAllPost, (data) => {
      setPostList(data['data']);
    }, isLoading, 'post');
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  }

  const filterPosts = () => {
    if (filter === 'Pending') {
      return postList.filter(post => post.post_status === 'Pending');
    } else if (filter === 'Draft') {
      return postList.filter(post => post.post_status === 'Draft');
    } else if (filter === 'Approved') {
      return postList.filter(post => post.post_status === 'Approved');
    } else if (filter === 'Rejected') {
      return postList.filter(post => post.post_status === 'Rejected');
    }
    return postList; // Return all posts if filter is not applied
  }

  const filteredPosts = filterPosts();

  const getBadgeClass = postStatus => {
    switch (postStatus) {
      case 'Pending':
        return 'badge-warning';
      case 'Draft':
        return 'badge-secondary';
      case 'Approved':
        return 'badge-success';
      case 'Rejected':
        return 'badge-danger';
      default:
        return 'badge-primary';
    }
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-md-9">
                <h6>All Post</h6>
              </div>
              <div className="col-md-3">
                  <select className="form-control" value={filter} onChange={handleFilterChange}>
                    <option value="All">All</option>
                    <option value="Draft">Draft</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>
              </div>
            </div>

            <div className="ms-panel-body">
              <div className="table-responsive">
                <table id="data-table-4" className="table w-100 thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">title</th>
                      <th scope="col">company name</th>
                      <th scope="col">city</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPosts.map((post) => (
                      <tr key={post.id}>
                        <th>#{String(post.id).padStart(4, '0')}</th>
                        <td>{post.title}</td>
                        <td>{post.company_name}</td>
                        <td>{post.job_cities}</td>
                        <td><span className={`badge ${getBadgeClass(post.post_status)} ml-5`}>
                                                    {post.post_status}</span></td>
                        <td>
                          <Link to="/admin/post-job-view" state={{ data: post }}>
                            <i className="far fa-eye ms-text-danger" />
                          </Link>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Posts;
