import React from 'react';
import { Bar } from 'react-chartjs-2';
import { generateLabelsOfMonths, getLast12Months, monthLabels, updateCounts } from './usefullFuntions';

const MixedChart = ({ graphData }) => {
  const last12Months = getLast12Months();
  const executiveGraphData = updateCounts(last12Months, graphData['executives']);
  const clientGraphData = updateCounts(last12Months, graphData['clients']);
  const userGraphData = updateCounts(last12Months, graphData['users']);


  const data = {
    labels: monthLabels(last12Months),
    datasets: [{
      label: "Executive",
      type: "line",
      borderColor: "#8e5ea2",
      data: executiveGraphData,
      fill: false
    }, {
      label: "Client",
      type: "line",
      borderColor: "#f7b11b",
      data: clientGraphData,
      fill: false
    }, {
      label: "User",
      type: "line",
      borderColor: "#3e95cd",
      data: userGraphData,
      fill: false
    },
    // {
    //   label: "Executive",
    //   type: "bar",
    //   backgroundColor: "#8e5ea2",
    //   data: executiveGraphData,
    // }, {
    //   label: "Client",
    //   type: "bar",
    //   backgroundColor: "#f7b11b",
    //   backgroundColorHover: "#3e95cd",
    //   data: clientGraphData,
    // },
    // {
    //   label: "User",
    //   type: "bar",
    //   backgroundColor: "#ff6c60",
    //   data: userGraphData,
    // }
    ]
  }

  // Sample options
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default MixedChart;
