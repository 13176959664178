import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DeleteModel from '../../components/deleteModel';

const coinsList = [
  {
    id: "1",
    name: "REFERRAL_BONUS",
    amount:100
  },
  {
    id: "2",
    name: "ON_JOB_APPLY",
    amount:120
  },
  {
    id: "2",
    name: "ON_RESUME_SUBMIT",
    amount:12
  },
  // Add more coins data as needed
];

const Coins = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCoins, setSelectedCoins] = useState(null);

  const handleOpenDeleteModal = (coins) => {
    setSelectedCoins(coins);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedCoins(null);
    setShowDeleteModal(false);
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>Coins List</h6>
              </div>
              <div className="col-6">
                <button type="button" className="btn btn-primary has-icon float-right" onClick={() => setShowRegisterModal(true)}>
                  <i className="fa fa-plus" aria-hidden="true"></i> Add Coins
                </button>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Coins ID</th>
                      <th scope="col">Name</th>
                      <th scope="col" className='text-right'>Amount</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coinsList.map((coins) => (
                      <tr key={coins.id}>
                        <th scope="row">{coins.id}</th>
                        <td>{coins.name}</td>
                        <td className='text-right'>{coins.amount}</td>
                        <td>
                          <span onClick={() => setShowRegisterModal(true)}><i className="fas fa-pencil-alt text-secondary" /></span>
                          <Link to="#" onClick={() => handleOpenDeleteModal(coins)}>
                            <i className="far fa-trash-alt ms-text-danger" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <DeleteModel
                  show={showDeleteModal}
                  onClose={handleCloseDeleteModal}
                  title="Confirm Delete"
                  bodyText={`Are you sure you want to delete ${selectedCoins?.name}?`}
                  itemName={selectedCoins?.name}
                  confirmButtonLabel="Delete"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showRegisterModal} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-primary">
          <h3 className="modal-title has-icon ms-icon-round text-white"><i className="flaticon-user bg-primary text-white" /> Coins</h3>
        </Modal.Header>
        <Modal.Body>
          <form method="post">
            <div class="ms-form-group has-icon">
              <label for="name">Name Of Coins</label>
              <input type="text" placeholder="B. ed, M. ed, MCA, BCA" class="form-control" id="name" name="name" />
              <i class="material-icons">school</i>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light" onClick={() => setShowRegisterModal(false)}>Cancel</button>
          <button type="button" className="btn btn-primary shadow-none">Submit</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Coins
