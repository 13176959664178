const options = {
    elements: {
        line: {
            tension: 0,
        },
    },
    legend: {
        display: false,
        position: 'bottom',
    },
    scales: {
        yAxes: [
            {
                display: false,
            },
        ],
        xAxes: [
            {
                display: false,
            },
        ],
    },
};

const chartData1 = () => {
    const data = [4100, 3800, 3200, 3400, 2700, 3600, 3300, 3700, 4900];
    const labels = ['Jan-11', 'Jan-12', 'Jan-13', 'Jan-14', 'Jan-15', 'Jan-16', 'Jan-17', 'Jan-18', 'Jan-19'];
    return {
        labels: labels,
        datasets: [
            {
                label: 'Data',
                borderColor: '#F7931A',
                pointBorderColor: '#F7931A',
                pointBackgroundColor: '#F7931A',
                pointHoverBackgroundColor: '#F7931A',
                pointHoverBorderColor: '#F7931A',
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                fill: true,
                backgroundColor: 'rgb(255, 76, 1)',
                borderWidth: 2,
                data: data,
            },
        ],
    };
};

const chartData2 = () => {
    const data = [2800, 2600, 2300, 2800, 3600, 2900, 3000, 3100, 3600, 3000, 3100, 3200];
    const labels = ['Jan-11', 'Jan-12', 'Jan-13', 'Jan-14', 'Jan-15', 'Jan-16', 'Jan-17', 'Jan-18', 'Jan-19', 'Jan-20', 'Jan-21', 'Jan-22'];
    return {
        labels: labels,
        datasets: [
            {
                label: 'Data',
                borderColor: '#F7931A',
                pointBorderColor: '#F7931A',
                pointBackgroundColor: '#F7931A',
                pointHoverBackgroundColor: '#F7931A',
                pointHoverBorderColor: '#F7931A',
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                fill: true,
                backgroundColor: 'rgba(255, 0, 24, 0.11)',
                borderWidth: 2,
                data: data,
            },
        ],
    };
};

const chartData3 = () => {
    return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        datasets: [
            {
                label: 'Data',
                borderColor: '#F7931A',
                pointBorderColor: '#F7931A',
                pointBackgroundColor: '#F7931A',
                pointHoverBackgroundColor: '#F7931A',
                pointHoverBorderColor: '#F7931A',
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                fill: true,
                backgroundColor: 'rgb(255, 76, 1)',
                borderWidth: 2,
                data: [5, 6, 8, 1, 5, 3, 9, 7, 5, 8, 7, 3, 6, 9, 1],
            },
        ],
    };
};

const chartData4 = () => {
    return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        datasets: [
            {
                label: 'Data',
                borderColor: '#F7931A',
                pointBorderColor: '#F7931A',
                pointBackgroundColor: '#F7931A',
                pointHoverBackgroundColor: '#F7931A',
                pointHoverBorderColor: '#F7931A',
                pointBorderWidth: 0,
                pointHoverRadius: 0,
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                fill: true,
                backgroundColor: 'rgba(255, 0, 24, 0.11)',
                borderWidth: 2,
                data: [1, 4, 7, 3, 5, 7, 6, 5, 8, 3, 5, 5, 4, 3, 7],
            },
        ],
    };
};

export { options, chartData1, chartData2, chartData3, chartData4 }