import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

import logo from '../../assets/img/logo.png';

class SideNavigationClient extends Component {

    removeoverlay = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    }
    componentDidMount() {
        function setActiveMenuItem() {
            $('.ms-main-aside .menu-item > a').off('click').on('click', function (e) {

                $(this).removeAttr('href');
                var element = $(this).parent('li');

                if (element.hasClass('active')) {
                    element.removeClass('active');
                    element.find('li').removeClass('active');
                    element.find('.collapse').slideUp();
                } else {
                    element.addClass('active');
                    element.children('.collapse').slideDown();
                    element.siblings('li').children('.collapse').slideUp();
                    element.siblings('li').removeClass('active');
                    element.siblings('li').find('li').removeClass('active');
                    element.siblings('li').find('.collapse').slideUp();
                }
            });
        }

        setActiveMenuItem();
    }

    render() {
        return (
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={this.removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left">
                    {/* Logo */}
                    {/* Navigation */}
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        {/* Dashboard */}
                        <li className="menu-item">
                            <div className='m-4 text-center'>
                                <Link className="pl-0 ml-0 text-center" to="/admin/dashboard">
                                    <img src={logo} alt="logo" />
                                </Link>
                                <span style={{ color: 'black' }}>Welcome ,  {localStorage.getItem('name')}</span>
                            </div>
                        </li>
                        <li className="menu-item">
                            <Link to="/client/dashboard" > <span> <i className="fa fa-archive fs-16" />Dashboard </span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/client/transactions">
                                <span><i className="fas fa-coins fs-16" />Coin </span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/client/post-job-list">
                                <span><i className="fa fa-briefcase fs-16" />Post Job List</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/client/profile-edit">
                                <span><i className="fa fa-user fs-16" />Profile</span>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link to="/client/chat-box">
                                <span><i className="fa fa-user fs-16" />Chat</span>
                            </Link>
                        </li>
                    </ul >
                </Scrollbar >
            </div >
        );
    }
}

export default SideNavigationClient;