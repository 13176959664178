import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { Dropdown, NavLink } from 'react-bootstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import logo from '../../../assets/img/logo.png';
import avatar from '../../../assets/img/avatar.png';

const TopNavigation = () => {
    const navigate = useNavigate();
    const [isAsideOpen, setIsAsideOpen] = useState(false);

    const addsidenavigation = () => {
        setIsAsideOpen(!isAsideOpen);
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $('.ms-aside-overlay.ms-overlay-left').toggleClass('d-block');
    };

    const topbaropen = () => {
        $('#ms-nav-options').toggleClass('ms-slide-down');
    };

    const logout = () => {
        localStorage.clear()
        navigate('/');
    };

    return (
        <nav className="navbar ms-navbar" style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            {/* <i class="fa fa-arrow-circle-left" aria-hidden="true"></i> */}
            <div className="ms-aside-toggler ms-toggler pl-0" onClick={addsidenavigation}>
                <span className="ms-toggler-bar bg-primary" />
                <span className="ms-toggler-bar bg-primary" />
                <span className="ms-toggler-bar bg-primary" />
            </div>
            <div className="logo-sn logo-sm ms-d-block-sm">
                <Link className="pl-0 ml-0 text-center navbar-brand mr-0" to="/">
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">
                {/* ... (existing code) */}
                <li className="ms-nav-item ms-nav-user dropdown">
                    <Dropdown className="custom-dropdown">
                        <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                            <img className="ms-user-img ms-img-round" src={avatar} alt="people" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right user-dropdown" aria-labelledby="userDropdown">
                            {/* ... (existing code) */}
                            <div className="dropdown-menu-footer">
                                <Link className="media fs-14 p-2" to="#" onClick={(e) => { e.preventDefault(); logout(); }}>
                                    <span><i className="flaticon-shut-down mr-2" /> Logout</span>
                                </Link>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
            <div className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler" onClick={topbaropen}>
                <span className="ms-toggler-bar bg-primary" />
                <span className="ms-toggler-bar bg-primary" />
                <span className="ms-toggler-bar bg-primary" />
            </div>
        </nav>
    );
};

export default TopNavigation;
