import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiAdmin, apiClient } from '../../utils/urls';
import { networkRequest } from '../../utils/network_request';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import { formatDate } from '../../components/usefullFuntions';

const PostJobView = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { dispatch } = useGlobalState();
    const [data, setData] = useState(location.state?.data);
    const [showReasonTextbox, setShowReasonTextbox] = useState(false);
    const [reason, setReason] = useState('');

    const isLoading = (state) => {
        dispatch({ type: 'SET_LOADING', payload: state });
    }

    useEffect(() => {
        // Update the data state when the location state changes
        setData(location.state?.data);
    }, [location.state?.data]);



    const handleDeleteModal = (id) => {
        Swal.fire({
            icon: 'warning',
            text: `Do you want to Unpublish This Job Post?`,
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Close',
            preConfirm: () => {
                Swal.showLoading();
                const isLoading = (status) => dispatch({ type: 'SET_LOADING', payload: status });
                return networkRequest(`${apiClient.unpublishPost}/${id}`, (r) => {
                    Swal.fire(r['message'], '', 'success').then(() => {
                        window.location.href = '/client/post-job-list';
                    });
                }, isLoading);
            },
        });
    }
    if (!data) {
        return <div>Loading...</div>;
    }


    const handleRejectClick = () => {
        setShowReasonTextbox(true);
    };



    const handleSubmit = (event, isApproved) => {
        event.preventDefault(); // Prevent default form submission behavior

        const formDataToSend = new FormData();
        formDataToSend.append('post_id', data.id); // Assuming data.id is the post_id
        formDataToSend.append('is_approved', isApproved ? 1 : 0); // 1 for approval, 0 for rejection
        formDataToSend.append('remark', isApproved ? '' : reason); // Reason only for rejection

        networkRequest(apiAdmin.approvePost, (responseData) => {
            Swal.fire({ title: 'Success', text: responseData['message'], icon: 'success' });
            setShowReasonTextbox(false); // Hide the reason textbox after submission
            setData({
                ...data,
                is_approved: isApproved ? '1' : '0', // Update the approval status
            });
            navigate('/admin/posts');
        }, isLoading, 'post', formDataToSend);
    };

    const getBadgeClass = postStatus => {
        switch (postStatus) {
            case 'Pending':
                return 'badge-warning';
            case 'Draft':
                return 'badge-secondary';
            case 'Approved':
                return 'badge-success';
            case 'Rejected':
                return 'badge-danger';
            default:
                return 'badge-primary';
        }
    };




    return (
        <div className="ms-content-wrapper">
            <div className="ms-panel">
                <div className="ms-panel-header row">
                    <div className="col-6">
                        <h6>{data.title}</h6>
                    </div>
                    <div className="col-6">
                        <Link to={"/admin/posts"}> <button type="button" className="btn btn-primary has-icon float-right">
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                        </button></Link>
                    </div>
                </div>
            </div>

            <div className="row mt-1">

                {/* <div className="col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body">
                            <div className="ms-panel-body">
                                <img className="d-block w-100" src={backImg} alt="job banner" style={{ height: 300 }} />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className=" col-md-6">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body">
                            <h4 className="section-title bold">Product Info</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Title</th>
                                        <td>{data.title}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Job Type</th>
                                        <td>{data.job_type}</td>
                                    </tr>

                                    <tr>
                                        <th scope="row">Location</th>
                                        <td>{data.location_type_details}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Salary</th>
                                        <td>{data.salary_min}-{data.salary_max} per/month</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">salary Type</th>
                                        <td>{data.salary_type}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Experience</th>
                                        <td>{data.experience_years}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Education Type</th>
                                        <td>{data.education_type}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Language Known</th>
                                        <td>{data.language_known}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Gadget Required</th>
                                        <td>{data.gadget_required}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Gender</th>
                                        <td>{data.gender}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Is Night Shift</th>
                                        <td>{data.is_night_shift === 1 ? 'No' : 'Yes'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Post Created at</th>
                                        <td>{data.created_at}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Post Is Approved</th>
                                        <td><span className={`badge ${getBadgeClass(data.post_status)} ml-5`}>
                                            {data.post_status}
                                        </span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Remark</th>
                                        <td>{data.remark}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Expiry Date</th>
                                        <td>{formatDate(data.expiry_date)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="new">
                                {/* <Link to="#" onClick={() => handleDeleteModal(data.id)}>
                                    <button type="button" className="btn btn-danger">Unpublish</button>
                                </Link> */}
                            </div>
                            {
                                (localStorage.getItem('user_type') === 'admin') && (
                                    <>
                                        <div className="new">
                                            <button type="button" className="btn btn-success" onClick={(e) => handleSubmit(e, true)}>Approve</button>
                                            <button type="button" className="btn btn-danger" onClick={handleRejectClick}>Reject</button>
                                        </div>
                                        <div className="col-12 mt-2">

                                            {showReasonTextbox && (
                                                <form onSubmit={(e) => handleSubmit(e, false)}>
                                                    <div className="input-group">
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Enter Reason"
                                                            value={reason}
                                                            onChange={(e) => setReason(e.target.value)}
                                                            rows={3} // Set the number of rows to 3 for three lines
                                                            required
                                                        />

                                                    </div>
                                                    <button type="submit" className="input-group btn btn-success btn-block">Submit</button>
                                                </form>
                                            )}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className=" col-md-6">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body">
                            <h4 className="section-title bold">Job Description </h4>

                            <div
                                dangerouslySetInnerHTML={{ __html: data.job_description }}
                            />
                        </div>
                        <div className="ms-quick-stats">
                            <div className="ms-stats-grid">
                                <i className="fa fa-bullhorn" />
                                <p className="ms-text-dark">1,033</p>
                                <span>Total Applicants</span>
                            </div>
                            <div className="ms-stats-grid">
                                <i className="fa fa-heart" />
                                <p className="ms-text-dark">{data.likes}</p>
                                <span>Likes</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );

}

export default PostJobView;