import React from 'react'
import SideNavigation from '../Admin/layouts/SideNavigation'
import TopNavigation from '../Admin/layouts/TopNavigation'
import { Navigate } from 'react-router-dom';


const ExecutiveAndAdminTemplate = ({ page: PageComponent }) => {
    var isLoggedIn = localStorage.getItem('logged_in');
    var userType = localStorage.getItem('user_type');

    if (isLoggedIn && (userType === 'admin' || userType === 'executive')) {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme">
                <SideNavigation />
                <main className="body-content">
                    <TopNavigation />
                    <PageComponent />
                </main>
            </div>);
    } else {
        return (<Navigate to="/login" />);
    }

}

export default ExecutiveAndAdminTemplate
