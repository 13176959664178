/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { networkRequest } from '../../utils/network_request';
import Swal from 'sweetalert2';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import Select from 'react-select';
import { apiClient, apiGeneral, imageUrls } from '../../utils/urls';

export default function ProfileEdit() {
    const navigate = useNavigate();
    const MAX_FILE_SIZE = 500 * 1024; // 500KB
    const { state, dispatch } = useGlobalState();
    const [profile, setProfile] = useState(null);
    const [errors, setErrors] = useState({});
    const [cityOptions, setCityOptions] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const id = localStorage.getItem('id')
    const profileStatus = localStorage.getItem('profile_status')

    const isLoading = (status) => {
        dispatch({ type: 'SET_LOADING', payload: status });
    }

    const [formData, setFormData] = useState({
        city_id: '',
        name: '',
        contact: '',
        overview: '',
        type: '',
        website: '',
        email: '',
        address: '',
        alternativeNumber: '',
    });

    const [fileData, setFileData] = useState({
        id_proof_link: null,
        profile_photo: null,
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear error when input changes
    };

    const handleFileChange = (e) => {
        setFileData({ ...fileData, [e.target.name]: e.target.files[0] });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear error when input changes
    };

    const validateForm = () => {
        console.log(fileData);
        const newErrors = {};

        // Validate city
        if (!formData.city_id || formData.city_id.trim() === '') {
            newErrors.city_id = 'city is required.';
        }

        // Validate Name
        if (!formData.name || formData.name.trim() === '') {
            newErrors.name = 'Name is required.';
        }
        // Validate type
        if (!formData.type || formData.type.trim() === '') {
            newErrors.type = 'Type is required.';
        }

        // Validate Contact
        if (!formData.contact || formData.contact.trim() === '' || isNaN(formData.contact) || formData.contact.length < 10) {
            newErrors.contact = 'Please enter a valid 10-digit contact number.';
        }

        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email || !emailRegex.test(formData.email.trim())) {
            newErrors.email = 'Please enter a valid email address.';
        }

        // Validate Address
        if (!formData.address || formData.address.trim() === '') {
            newErrors.address = 'Address is required.';
        }

        // Validate Alternative Number
        if (
            formData.alternativeNumber &&
            (isNaN(formData.alternativeNumber) || formData.alternativeNumber.length !== 10)
        ) {
            newErrors.alternativeNumber = 'Please enter a valid 10-digit alternative number.';
        }

        // Validate Date of Birth

        if (profileStatus === "1") {
            console.log('edit')
            if (fileData.id_proof_link) {
                if (!fileData.id_proof_link.type.startsWith('image/')) {
                    newErrors.id_proof_link = 'Please upload a valid image file for Id card Photo.';
                } else if (fileData.id_proof_link.size > MAX_FILE_SIZE) {
                    newErrors.id_proof_link =
                        'File size exceeds the maximum allowed size (500KB) for Id card Photo.';
                }
            }
            if (fileData.profile_photo) {
                if (!fileData.profile_photo.type.startsWith('image/')) {
                    newErrors.profile_photo = 'Please upload a valid image file for profile photo .';
                } else if (fileData.profile_photo.size > MAX_FILE_SIZE) {
                    newErrors.profile_photo =
                        'File size exceeds the maximum allowed size (500KB) for profile photo.';
                }
            }
        } else {
            console.log("new")
            if (!fileData.profile_photo) {
                newErrors.profile_photo = 'profile photo is required.';
            } else if (!fileData.profile_photo.type.startsWith('image/')) {
                newErrors.profile_photo = 'Please upload a valid image file for profile photo.';
            } else if (fileData.profile_photo.size > MAX_FILE_SIZE) {
                newErrors.profile_photo =
                    'File size exceeds the maximum allowed size (500KB) for profile photo.';
            }

            // Validate File Inputs
            if (!fileData.id_proof_link) {
                newErrors.id_proof_link = 'Id card Photo is required.';
            } else if (!fileData.id_proof_link.type.startsWith('image/')) {
                newErrors.id_proof_link = 'Please upload a valid image file for Id card Photo.';
            } else if (fileData.id_proof_link.size > MAX_FILE_SIZE) {
                newErrors.id_proof_link =
                    'File size exceeds the maximum allowed size (500KB) for Id card Photo.';
            }

        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("submit")
        if (!validateForm()) {
            // If validation fails, don't submit the form
            console.log(errors);
            return;
        }
        // console.log("Validation Error")
        const formDataToSend = new FormData();

        // Append form data
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        // Append file data
        for (const key in fileData) {
            formDataToSend.append(key, fileData[key]);
        }
        var url = apiClient.clientAdd;
        if (profileStatus === "1") {
            url = `${apiClient.clientEdit}`;
        }

        networkRequest(url, (resp) => {
            Swal.fire({
                title: 'Success',
                text: resp['message'],
                icon: 'warning',
            });
            localStorage.setItem('profile_status', 1);
            return navigate(-1);
        }, isLoading, 'post', formDataToSend);


    };

    const handleCityChange = (selectedCity) => {
        setSelectedCity(selectedCity);
        setFormData({ ...formData, city_id: selectedCity.value });
    };

    const fetchCityData = () => {
        networkRequest(apiGeneral.citiesGet, (data) => {
            const modifiedCityOptions = data['data'].map(city => ({
                value: city.id,
                label: `${city.name}-${city.state}`,
            }));
            setCityOptions(modifiedCityOptions);
        }, isLoading);

        networkRequest(apiClient.clientGetProfile, (response) => {
            setFormData((data) => ({
                ...data,
                name: response['data']['status']['name'],
                contact: response['data']['status']['username']
            }))
        }, isLoading);
    };

    useEffect(() => {
        fetchCityData();
    }, []);


    useEffect(() => {
        var url = apiClient.clientGetProfile;
        networkRequest(url, (data) => {
            console.log(data['data']['profile']);
            var profile = data['data']['profile'];
            if (profile !== null) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    city_id: profile['city_id'],
                    name: profile['name'],
                    overview: profile['overview'],
                    website: profile['website'],
                    email: profile['email'],
                    type: profile['type'],
                    contact: profile['contact'],
                    address: profile['address'],
                    alternativeNumber: profile['alternativeNumber'],
                    // Add other fields as needed
                }));
                setProfile({
                    id_proof_link: profile['id_proof_link'],
                    profile_photo: profile['profile_photo'],
                });
            }
        }, isLoading);
    }, [id]);

    useEffect(() => {
        const defaultSelectedCity = cityOptions.find(city => city.value === formData.city_id);
        setSelectedCity(defaultSelectedCity);
    }, [formData.city_id]);





    return (
        <div className="ms-content-wrapper">
            <div className="row">
                <div className="col-xl-12">
                    <div className="ms-panel">
                        <div className="ms-panel-header row">
                            <div className="col-10">
                                <h6>Add Client <span className='text-danger'><u>{(profileStatus === "0") ? "(Please Complete Your Profile To Go Further Process)" : ""}</u></span></h6>

                            </div>
                            <div className="col-2">
                                <Link to={"/admin/clients"}> <button type="button" className="btn btn-primary has-icon float-right">
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                </button></Link>
                            </div>
                        </div>
                        <div className="ms-panel-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    {/* Name */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomName">Name</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                id="name"
                                                name="name"
                                                placeholder="Name"
                                                value={formData.name}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}

                                            />
                                            {errors.name && <div className="invalid-tooltip">{errors.name}</div>}
                                        </div>
                                    </div>


                                    {/* Overview */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomName">Overview</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className={`form-control`}
                                                id="overview"
                                                name="overview"
                                                placeholder="Overview"
                                                value={formData.overview}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}

                                            />

                                        </div>
                                    </div>

                                    {/* website */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomName">Website</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className={`form-control`}
                                                id="website"
                                                name="website"
                                                placeholder="website"
                                                value={formData.website}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}

                                            />
                                        </div>
                                    </div>

                                    {/* type */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomType">Type</label>
                                        <div className="input-group">
                                            <select className={`form-control ${errors.type ? 'is-invalid' : ''}`} id="type" name="type"
                                                value={formData.type} // Corrected to formData.type
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange} required>
                                                <option value="" disabled>Select Type</option>
                                                <option value="consultancy">Consultancy</option>
                                                <option value="company">Company</option>
                                            </select>
                                            {errors.type && <div className="invalid-tooltip">{errors.type}</div>}
                                        </div>
                                    </div>


                                    {/* Contact */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomContact">Contact</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className={`form-control ${errors.contact ? 'is-invalid' : ''}`}
                                                id="contact"
                                                name="contact"
                                                placeholder="Contact"
                                                value={formData.contact}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}
                                            />
                                            {errors.contact && <div className="invalid-tooltip">{errors.contact}</div>}
                                        </div>
                                    </div>
                                    {/* Email */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomEmail">Email</label>
                                        <div className="input-group">
                                            <input
                                                type="email"
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                value={formData.email}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}

                                            />
                                            {errors.email && <div className="invalid-tooltip">{errors.email}</div>}
                                        </div>
                                    </div>

                                    {/* City */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomCity">City</label>
                                        <Select
                                            options={cityOptions}
                                            onChange={handleCityChange}
                                            value={selectedCity}
                                            isSearchable
                                            placeholder="Search for a city"
                                        />
                                    </div>

                                    {/* Address */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomAddress">Address</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                                id="address"
                                                name="address"
                                                placeholder="Address"
                                                value={formData.address}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}

                                            />
                                            {errors.address && <div className="invalid-tooltip">{errors.address}</div>}
                                        </div>
                                    </div>

                                    {/* Alternative Number */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustomAltNumber">Alternative Number</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className={`form-control ${errors.alternativeNumber ? 'is-invalid' : ''}`}
                                                id="alternativeNumber"
                                                name="alternativeNumber"
                                                placeholder="Alternative Number"
                                                value={formData.alternativeNumber}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}
                                                min={5000000000}
                                                max={9999999999}
                                            />
                                            {errors.alternativeNumber && (
                                                <div className="invalid-tooltip">{errors.alternativeNumber}</div>
                                            )}
                                        </div>
                                    </div>
                                    {/* ID Proof Document */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationprofile_photo">
                                            Profile Photo
                                            {profile && profile.profile_photo && (
                                                <span>
                                                    <a
                                                        target='_blank'
                                                        rel="noreferrer"
                                                        href={`${imageUrls.client}${profile.profile_photo}`}
                                                        aria-label="View Profile Photo"
                                                    >
                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                    </a>
                                                </span>
                                            )}
                                        </label>

                                        <div className="input-group">
                                            <input
                                                type="file"
                                                className={`form-control ${errors.profile_photo ? 'is-invalid' : ''}`}
                                                id="profile_photo"
                                                name="profile_photo"
                                                placeholder="profile photo"
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleFileChange}

                                            />
                                            {errors.id_proof_link && (
                                                <div className="invalid-tooltip">{errors.profile_photo}</div>
                                            )}
                                        </div>
                                    </div>

                                    {/* ID Proof Document */}
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationid_proof_link">
                                            ID Proof Document
                                            {profile && profile.id_proof_link && (
                                                <span>
                                                    <a
                                                        target='_blank'
                                                        rel="noreferrer"
                                                        href={`${imageUrls.client}${profile.id_proof_link}`}
                                                        aria-label="View ID Proof Document"
                                                    >
                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                    </a>
                                                </span>
                                            )}
                                        </label>

                                        <div className="input-group">
                                            <input
                                                type="file"
                                                className={`form-control ${errors.id_proof_link ? 'is-invalid' : ''}`}
                                                id="id_proof_link"
                                                name="id_proof_link"
                                                placeholder="ID Proof Document"
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleFileChange}

                                            />
                                            {errors.id_proof_link && (
                                                <div className="invalid-tooltip">{errors.id_proof_link}</div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <button className="btn btn-primary" disabled={state.value} type="submit">
                                    Submit form
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
