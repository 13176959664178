import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div className='container-fluid card'>
            <h1>Privacy Policy</h1>
            <p>Effective date: June 21, 2024</p>

            <h2>1. Introduction</h2>
            <p>Welcome to 9to5. This Privacy Policy explains how we collect, use, and protect your information when you use our app.</p>

            <h2>2. Information We Collect</h2>
            <p>Our app requests the following permissions:</p>
            <ul>
                <li><strong>Internet:</strong> This permission is used to access data from our server.</li>
                <li><strong>Read External Storage:</strong> This permission is used to upload your resume from your device's storage.</li>
            </ul>
            <p>We do not collect any other personal information from our users.</p>

            <h2>3. Use of Information</h2>
            <p>The permissions we request are solely used for the following purposes:</p>
            <ul>
                <li><strong>Internet:</strong> To fetch and display data from our server.</li>
                <li><strong>Read External Storage:</strong> To enable you to upload your resume from your device.</li>
            </ul>

            <h2>4. Data Retention and Deletion</h2>
            <p>Data uploaded by users, such as resumes, cannot be deleted from our servers. We retain this data to provide continuous access and functionality to our users.</p>

            <h2>5. Security</h2>
            <p>We take reasonable measures to protect the information that we collect from unauthorized access, use, or disclosure. However, please be aware that no method of transmitting or storing data is completely secure.</p>

            <h2>6. Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

            <h2>7. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at contact@go9to5.com.</p>
          
        </div>
    )
}
