import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import { networkRequest } from '../../utils/network_request';
import { apiAdmin } from '../../utils/urls';
import Swal from 'sweetalert2';
import { educationCategories } from '../../data/drop-down-data';

const Educations = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [formData, setFormData] = useState({
    'id': '',
    'name': '',
    'category': ''
  })
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useGlobalState();

  const isLoading = (state) => {
    dispatch({ type: 'SET_LOADING', payload: state });
  }

  const loadData = () => {
    networkRequest(apiAdmin.educationsGet, (data) => {
      setEducationList(data['data']);
    }, isLoading);
  }

  useEffect(() => {
    loadData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleDelete = (e) => {
    console.log(e.id);
    Swal.fire({
      icon: 'warning',
      text: `Do you want to delete Education ${e.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Close',
      preConfirm: () => {
        Swal.showLoading();
        networkRequest(`${apiAdmin.educationDelete}/${e.id}`, (res) => {
          Swal.fire('Client deleted successfully!', '', 'success');
        }, isLoading)
          .then(() => {
            setEducationList(data => data.filter(i => i.id !== e.id));
          })
          .catch(() => Swal.fire('Error deleting Client. Please try again.', '', 'error'));
      },
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = new FormData();

    for (const key in formData) {
      form.append(key, formData[key]);
    }

    networkRequest(apiAdmin.educationAdd, (data) => {
      Swal.fire({ title: 'success', text: data['message'], icon: 'success' })
      loadData();
      setShowRegisterModal(false)
    }, isLoading, 'post', form);
  }

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>Educations List</h6>
              </div>
              <div className="col-6">
                <button type="button" className="btn btn-primary has-icon float-right" onClick={() => {
                  setFormData({
                    name: '',
                    id: '',
                    categories: ''
                  });
                  setShowRegisterModal(true)
                }}>
                  <i className="fa fa-plus" aria-hidden="true"></i> Add Educations
                </button>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Education ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {educationList.map((education) => (
                      <tr key={education.id}>
                        <th scope="row">{education.id}</th>
                        <td>{education.name}</td>
                        <td>{education.category}</td>
                        <td>
                          <span onClick={() => {
                            setFormData(education)
                            setShowRegisterModal(true)
                          }}><i className="fas fa-pencil-alt text-secondary" /></span>
                          <Link to="#" onClick={() => handleDelete(education)}>
                            <i className="far fa-trash-alt ms-text-danger" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showRegisterModal} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-primary">
          <h3 className="modal-title has-icon ms-icon-round text-white"><i className="flaticon-user bg-primary text-white" /> Educations</h3>
        </Modal.Header>
        <form method="post" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="ms-form-group has-icon">
              <label htmlFor="name">Education Category</label>
              <select
                className='form-control'
                name='category'
                id='category'
                value={formData.category}
                onChange={handleInput}
              >
                <option value="" disabled hidden>Select Education Category</option>
                {educationCategories && Object.entries(educationCategories).length > 0 ? (
                  Object.entries(educationCategories).map(([key, value], index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))
                ) : (
                  <option disabled>No data available.</option>
                )}
              </select>
              <i className="material-icons">school</i>
            </div>
            <div className="ms-form-group has-icon">
              <label htmlFor="name">Name Of Educations</label>
              <input type="text" placeholder="B. ed, M. ed, MCA, BCA" className="form-control" id="name" name="name" onChange={handleInput} value={formData.name} required />
              <i className="material-icons">school</i>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={() => setShowRegisterModal(false)}>Cancel</button>
            <button type="submit" className="btn btn-primary shadow-none">Submit</button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default Educations
