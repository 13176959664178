import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { networkRequest } from '../../utils/network_request';
import { apiClient } from '../../utils/urls';
import { useGlobalState } from '../../global_state/GlobalStateContext';

const BookmarkUserList = () => {
    const [bookmarkUserList, setBookmarkUserList] = useState([]);
    const location = useLocation();
    const { data } = location.state;
    console.log(data);
  
    const { dispatch } = useGlobalState();
    const isLoading = (state) => {
      dispatch({ type: 'SET_LOADING', payload: state });
  }

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(bookmarkUserList);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Applicant List');
        XLSX.writeFile(wb, 'applicant_list.xlsx');
    }

    useEffect(() => {
      loadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
      networkRequest(`${apiClient.postGetBookmarkList}/${data}`, (data) => {
        setBookmarkUserList(data['data']);
      }, isLoading);
  }


return(
    <div className="ms-content-wrapper m-1">
    <div className="row">
      <div className="col-xl-12">
        <div className="ms-panel">
          <div className="ms-panel-header row">
            <div className="col-6">
              <h6>Bookmark User List</h6>
            </div>
            <div className="col-6 text-align-right">
            <Link to="#" onClick={exportToExcel}><i className="fa fa-download mr-2" />Export to Excel</Link>
            </div>
          </div>
          <div className="ms-panel-body">
            <div className="table-responsive">
              <table id="clientTable" className="table table-hover thead-primary">
                <thead>
                  <tr>
                    <th scope="col">User ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {bookmarkUserList  && (bookmarkUserList.map((list) => (
                    <tr>
                      <th>1</th>
                      <td>{list.name}</td>
                      <td><Link to='#'>jjd</Link></td>
                      <td>9889898098</td>
                      <td>
                       
                      </td>
                    </tr>
                 )))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
}


export default BookmarkUserList;