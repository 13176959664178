import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from "./pages/Login";
import Dashboard from "./pages/Admin/Dashboard";
import ClientDashboard from './pages/Client/Dashboard'
import Executive from "./pages/Admin/Executive";
import Client from "./pages/Admin/Client";
import Users from "./pages/Admin/Users";
import JobCategory from "./pages/Admin/JobCategory";
import Coins from "./pages/Admin/coins";
import ExecutiveProfile from "./pages/Admin/executive/executive_profile";
import ClientProfile from "./pages/Admin/client/client_profile";
import AdminTemplate from "./pages/layouts/AdminTemplate";
import ClientTemplate from "./pages/layouts/ClientTemplat";
import ErrorPage from "./pages/ErrorPage";
import Preloader from "./components/preloader";
import { GlobalStateProvider } from "./global_state/GlobalStateContext";
import ExecutiveAdd from "./pages/Admin/executive/executiveAdd";
import TransferBalance from "./pages/Admin/transfer_balance";
import Cities from "./pages/Admin/cities";
import ExecutiveAndAdminTemplate from "./pages/layouts/ExecutiveAndAdminTemplate";
import Educations from "./pages/Admin/Educations";
import RegisterUser from "./pages/Admin/executive/register_user";
import ProfileEdit from "./pages/Client/profile_edit";
import Packages from "./pages/Admin/Packages";
import PostJobForm from "./pages/Client/post-job";
import Transactions from "./pages/Client/Transactions";
import PostJobList from "./pages/Client/post-list";
import PostJobView from "./pages/Client/post-job-view";
import Posts from "./pages/Admin/Posts";
import ApplicantList from "./pages/Client/applicant-list";
import BookmarkUserList from "./pages/Client/bookmark-user-list";
import ChangePassword from "./pages/ChangePassword";
import PurchasePackage from "./pages/Client/purchase_package";
import ChatBox from "./pages/Client/chat-box";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <GlobalStateProvider>
      <BrowserRouter basename={'/'}>
        <div>
          <Preloader />
          <Routes>
            {/* <Route exact path='/' element={<ComingSoon />} /> */}
            {/* admin routes */}
            <Route exact path='/' element={<Login />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path="/admin/change-password" element={<AdminTemplate page={ChangePassword} />} />
            {/* dashboard */}
            <Route path="/admin/dashboard" element={<ExecutiveAndAdminTemplate page={Dashboard} />} />
            {/* executive manager admin */}
            <Route path='/admin/executives' element={<AdminTemplate page={Executive} />} />
            <Route path='/admin/executive/:id' element={<AdminTemplate page={ExecutiveProfile} />} />
            <Route path='/admin/executive/add' element={<AdminTemplate page={ExecutiveAdd} />} />
            <Route path='/admin/executive/edit/:id' element={<AdminTemplate page={ExecutiveAdd} />} />
            {/* admin client */}
            <Route path='/admin/clients' element={<ExecutiveAndAdminTemplate page={Client} />} />
            <Route path='/admin/client/:id' element={<ExecutiveAndAdminTemplate page={ClientProfile} />} />
            {/* job categories manager */}
            <Route path='/admin/job_categories' element={<AdminTemplate page={JobCategory} />} />
            {/* manager cities */}
            <Route path='/admin/cities' element={<AdminTemplate page={Cities} />} />
            {/* manage packages */}
            <Route path='/admin/packages' element={<AdminTemplate page={Packages} />} />
            {/* manage educations */}
            <Route path='/admin/educations' element={<AdminTemplate page={Educations} />} />

            {/* manage coins */}
            <Route path='/admin/coins' element={<AdminTemplate page={Coins} />} />
            {/* manage users  */}
            <Route path='/admin/users' element={<ExecutiveAndAdminTemplate page={Users} />} />
            {/* transfer coins general api */}
            <Route path='/admin/transfer_balance/:id' element={<ExecutiveAndAdminTemplate page={TransferBalance} />} />
            {/* user registration form */}
            <Route path='/admin/user/register' element={<ExecutiveAndAdminTemplate page={RegisterUser} />} />
            <Route path='/admin/posts' element={<ExecutiveAndAdminTemplate page={Posts} />} />
            {/* client registration form  */}
            <Route path='/admin/client/register' element={<ExecutiveAndAdminTemplate page={RegisterUser} />} />

            <Route path="/admin/post-job-view" element={<AdminTemplate page={PostJobView} />} />
            {/* <Route path='/admin/user/add' element={<ExecutiveAndAdminTemplate page={UserAdd} />} /> */}
            {/* <Route path='/admin/user/:id' element={<ExecutiveAndAdminTemplate page={UserProfile} />} /> */}
            {/* <Route path='/admin/user/edit/:id' element={<ExecutiveAndAdminTemplate page={UserAdd} />} /> */}

            {/* admin routes end */}
            <Route path="/client/dashboard" element={<ClientTemplate page={ClientDashboard} />} />
            <Route path="/client/post_job" element={<ClientTemplate page={PostJobForm} />} />
            <Route path="/client/post-job-list" element={<ClientTemplate page={PostJobList} />} />
            <Route path="/client/applicant-list" element={<ClientTemplate page={ApplicantList} />} />
            <Route path="/client/bookmark-user-list" element={<ClientTemplate page={BookmarkUserList} />} />
            <Route path="/client/post-job-view" element={<ClientTemplate page={PostJobView} />} />
            <Route path='/client/profile-edit' element={<ClientTemplate page={ProfileEdit} />} />
            <Route path="/client/transactions" element={<ClientTemplate page={Transactions} />} />
            <Route path="/client/purchase_package" element={<ClientTemplate page={PurchasePackage} />} />
            <Route path="/client/chat-box" element={<ClientTemplate page={ChatBox} />} />


            <Route exact path='/delete-account' element={<div>
              <h3>Want to delete Account ?</h3>
              <p>Please contact us on <b>contact@go9to5.com</b> mention in subject account deletion and don't forgot to mention your registered contact no in message.</p>
            </div>} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </GlobalStateProvider>
  );
}

export default App;
