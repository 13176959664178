import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { networkRequest } from '../../../utils/network_request';
import { apiAdmin, apiExecutive, apiGeneral } from '../../../utils/urls';
import Swal from 'sweetalert2';
import { useGlobalState } from '../../../global_state/GlobalStateContext';
import OTPInput from 'react-otp-input';

export default function RegisterUser() {
    const navigate = useNavigate();
    const { state, dispatch } = useGlobalState();
    const [errors, setErrors] = useState({});
    const [showOtpButton, setShowOtpButton] = useState(false);

    const isLoading = (status) => {
        dispatch({ type: 'SET_LOADING', payload: status });
    }

    const [formData, setFormData] = useState({
        type: '',
        name: '',
        contact: '',
        otp: '',
        coins: 0,
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear error when input changes
        if (e.target.name === 'contact' && e.target.value.length >= 10) {
            setShowOtpButton(true);
        } else {
            setShowOtpButton(false);
        }
    };


    const validateForm = () => {
        const newErrors = {};

        // Validate Name
        if (!formData.type || formData.type.trim() === '') {
            newErrors.type = 'select type is required.';
        }

        // Validate Name
        if (!formData.name || formData.name.trim() === '') {
            newErrors.name = 'Name is required.';
        }

        // Validate Contact
        if (!formData.contact || formData.contact.trim() === '' || isNaN(formData.contact) || formData.contact.length < 10) {
            newErrors.contact = 'Please enter a valid 10-digit contact number.';
        }
        // Validate OTP
        if (!formData.otp || formData.otp.trim() === '') {
            newErrors.otp = 'OTP is required.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            console.log(errors);
            return;
        }

        const formDataToSend = new FormData();

        // Append form data
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        var url = apiAdmin.executiveRegisterUser;
        if (localStorage.getItem('user_type') === 'executive')
            url = apiExecutive.executiveRegisterUser;

        networkRequest(url, (resp) => {
            Swal.fire({
                title: 'Opps',
                text: resp['message'],
                icon: 'warning',
                type: 'success'
            });
            return navigate(-1);
        }, isLoading, 'post', formDataToSend);
    };

    const sendOtp = () => {
        const otpFromData = new FormData();
        otpFromData.append('contact', formData.contact);
        networkRequest(apiGeneral.otpSend, (response) => {
            Swal.fire({
                title: 'Info',
                text: response['message'],
                icon: 'warning',
            });
        }, isLoading, 'post', otpFromData);
    }


    return (
        <div className="ms-content-wrapper">
            <div className="row">
                <div className="col-xl-12">
                    <div className="ms-panel">
                        <div className="ms-panel-header row">
                            <div className="col-6">
                                <h6>Register Client</h6>
                            </div>
                            <div className="col-6">
                                <Link to={"/admin/users"}> <button type="button" className="btn btn-primary has-icon float-right">
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                </button></Link>
                            </div>
                        </div>
                        <div className="ms-panel-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="col-md-8 mb-3">
                                        <label htmlFor="validationCustomName">Select Type</label>
                                        <div className="input-group">
                                            <select
                                                id='type'
                                                name="type"
                                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                value={formData.type}
                                                onChange={handleInputChange}>
                                                <option value="" disabled selected hidden>Choose...</option>
                                                <option value="Client" >Client/Company</option>
                                                <option value="User" >Candidate/User</option>
                                            </select>
                                            {errors.type && <div className="invalid-tooltip">{errors.type}</div>}
                                        </div>
                                    </div>
                                    {/* Name */}
                                    <div className="col-md-8 mb-3">
                                        <label htmlFor="validationCustomName">Name</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                id="name"
                                                name="name"
                                                placeholder="Name"
                                                value={formData.name}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}

                                            />
                                            {errors.name && <div className="invalid-tooltip">{errors.name}</div>}
                                        </div>
                                    </div>

                                    {/* Contact */}
                                    <div className="col-md-8 mb-3">
                                        <label htmlFor="validationCustomContact">Contact</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className={`form-control ${errors.contact ? 'is-invalid' : ''}`}
                                                id="contact"
                                                name="contact"
                                                placeholder="Contact"
                                                value={formData.contact}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}
                                            />
                                            {errors.contact && <div className="invalid-tooltip">{errors.contact}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <button className="btn btn-success" disabled={!showOtpButton} type="button" onClick={sendOtp}>
                                            Send OTP
                                        </button>
                                    </div>

                                    {/* OTP */}
                                    <div className="col-md-8 mb-3">
                                        <label htmlFor="validationOtp">OTP</label>
                                        <div className="input-group">
                                            <OTPInput
                                                value={formData.otp}
                                                onChange={(e) => setFormData({ ...formData, otp: e })}
                                                numInputs={4}
                                                separator={<span style={{ width: "8px" }}></span>}
                                                isInputNum={true}
                                                shouldAutoFocus={true}
                                                inputStyle={{
                                                    margin: "2px",
                                                    border: "1px solid",
                                                    borderRadius: "8px",
                                                    width: "54px",
                                                    height: "54px",
                                                    fontSize: "20px",
                                                    color: "#000",
                                                    fontWeight: "400",
                                                    caretColor: "blue"
                                                }}
                                                focusStyle={{
                                                    border: "2px solid",
                                                    outline: "none"
                                                }}
                                                renderInput={(props) => <input {...props} />}
                                            />
                                            {errors.otp && <div className="invalid-tooltip">{errors.otp}</div>}
                                        </div>
                                    </div>
                                    {/* Name */}
                                    <div className="col-md-8 mb-3">
                                        <label>Coin</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className={`form-control ${errors.coins ? 'is-invalid' : ''}`}
                                                id="coins"
                                                name="coins"
                                                min={0}
                                                placeholder="Coins"
                                                value={formData.coins}
                                                aria-describedby="inputGroupPrepend"
                                                onChange={handleInputChange}

                                            />
                                            {errors.coin && <div className="invalid-tooltip">{errors.coin}</div>}
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary" disabled={state.value} type="submit">
                                    Submit form
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
