import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { apiGeneral } from '../utils/urls';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useGlobalState } from '../global_state/GlobalStateContext';
import { Modal } from 'react-bootstrap';

const Login = () => {

    const [isFormValid, setIsFormValid] = useState(false);
    const [showForgotModal, setShowForgotModal] = useState(false);
    const { state, dispatch } = useGlobalState();
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otpValue, setOtpValue] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const isLoading = (status) => {
        dispatch({ type: 'SET_LOADING', payload: status });
    }

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handleInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        setShowOtpInput(true);
    };

    useEffect(() => {
        isAlreadyLoggedIn();
    }, []);

    useEffect(() => {
        window.addEventListener('load', formValidation);
        return () => {
            window.removeEventListener('load', formValidation);
        };
    }, []);

    useEffect(() => {
        // Additional dependencies may be needed for formValidation
        formValidation();
    }, [formData]); // Add necessary dependencies

    const isAlreadyLoggedIn = () => {
        if (localStorage.getItem("token")) {
            var userType = localStorage.getItem('user_type');
            redirectUser(userType);
        }
    }
    const formValidation = () => {
        var forms = document.getElementsByClassName('needs-validation');
        Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    setIsFormValid(true);
                }
                form.classList.add('was-validated');
            }, false);
        });
    };

    function redirectUser(params) {
        if (params === 'admin') {
            return navigate('/admin/dashboard');
        } else if (params === 'client') {
            return navigate('/client/dashboard');
        } else if (params === 'executive') {
            return navigate('/admin/dashboard');
        } else if (params === 'client') {
            return navigate('/client/dashboard');
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'something went wrong',
            });
        }
    }

    const handleFormSubmit = (event) => {
        isLoading(true);
        formValidation();
        event.preventDefault();
        // Check username and password
        if (isFormValid) {
            let data = new FormData();
            data.append('username', formData.username);
            data.append('password', formData.password);

            axios.post(apiGeneral.login, data).then(response => {
                if (response.data['success']) {
                    localStorage.setItem('logged_in', true);
                    localStorage.setItem("token", response.data["access_token"]);
                    localStorage.setItem("refresh_token", response.data["refresh_token"]);
                    localStorage.setItem('username', response.data['user_data']['username']);
                    localStorage.setItem('name', response.data['user_data']['name']);
                    localStorage.setItem('user_type', response.data['user_data']['user_type']);
                    if (response.data['user_data']['user_type'] === 'client') {
                        localStorage.setItem('profile_status', response.data['user_data']['profile_status']);
                    }
                    redirectUser(response.data['user_data']['user_type']);
                    isLoading(false);
                } else {
                    isLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: response.data['message'],
                    });
                }
            })
                .catch(error => {
                    // Handle errors
                    isLoading(false);
                    console.error('Error:', error.message);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.message,
                    });
                });

        } else {
            isLoading(false);
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Invalid username or password. Please try again.',
            });

        }
    };


    return (
        <div className="ms-content-wrapper ms-auth">
            <div className="ms-auth-container">
                <div className="ms-auth-col">
                    <div className="ms-auth-bg" />
                </div>
                <div className="ms-auth-col">
                    <div className="ms-auth-form">
                        <form className="needs-validation" noValidate onSubmit={handleFormSubmit}>
                            <h3>Login to Client Account</h3>
                            <p>Please enter your Mobile No. and password to continue</p>
                            <div className="mb-3">
                                <label htmlFor="validationCustom08">Mobile No.</label>
                                <div className="input-group">
                                    <input
                                        type="username"
                                        className="form-control"
                                        id="username"
                                        placeholder="Enter Mobile No."
                                        value={formData.username}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">Please provide a valid username.</div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="validationCustom09">Password</label>
                                <div className="input-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="invalid-feedback">Please provide a password.</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="ms-checkbox-wrap">
                                    <input className="form-check-input" type="checkbox" defaultValue /> <i className="ms-checkbox-check" />
                                </label> <span> Remember Password </span>
                                <label className="d-block mt-3">
                                    <Link to="" className="btn-link" onClick={() => { setShowForgotModal(true) }}>Forgot Password?</Link>
                                </label>
                            </div>
                            <button className="btn btn-primary mt-4 d-block w-100" type="submit">Sign In</button>
                            <p className="mb-0 mt-3 text-center">Don't have an account? <Link className="btn-link" to="/privacy-policy">Privacy Policy</Link></p>
                            <p className="mb-0 mt-3 text-center">Want To Delete Account? <Link className="btn-link" to="/delete-account">Click Here</Link></p>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={showForgotModal} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className="bg-primary">
                    <h3 className="modal-title has-icon ms-icon-round text-white"><i className="flaticon-phone bg-primary text-white" /> Reset Password</h3>
                </Modal.Header>
                <form method="post" onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="ms-form-group has-icon">
                            <label htmlFor="name">Enter Mobile Number</label>
                            <input type="text" placeholder="Enter Register Mobile No." className="form-control" id="mobile" name="mobile" onChange={handleInput} value={formData.mobile} required />
                        </div>
                        {showOtpInput && (
                            <div className="ms-form-group has-icon">
                                <label htmlFor="otp">Enter Password sended on Phone</label>
                                <input type="text" placeholder="Enter Password" className="form-control" id="otp" name="otp" onChange={(e) => setOtpValue(e.target.value)} value={otpValue} required />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-light" onClick={() => setShowForgotModal(false)}>Cancel</button>
                        {!showOtpInput && (
                            <button type="submit" className="btn btn-primary shadow-none">Submit</button>
                        )}
                        {showOtpInput && (
                            <button type="submit" className="btn btn-primary shadow-none">Reset Password</button>
                        )}
                    </Modal.Footer>
                </form>

            </Modal>
        </div>
    );
}

export default Login;
