import { Line as LineChart } from 'react-chartjs-2';
import { options } from '../../../data/dashboardTilesChartData';


const DashboardTiles = ({ title, value, chartData }) => {
    const paddedValue = value.toString().padStart(4, '0');

    return (
        <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                <div className="ms-card-body media">
                    <div className="media-body">
                        <span className="black-text"><strong>{title}</strong></span>
                        <h2>{paddedValue}</h2>
                    </div>
                </div>
                <LineChart data={chartData} options={options} />
            </div>
        </div>
    )
}

export default DashboardTiles;
