const educationCategories = {
    sscAndBelow: '10th Or Below',
    hscPassed: '12th Pass',
    diploma: 'Diploma',
    graduation: 'Graduation',
    pg: 'Post Graduation'
}

const JobTypes = {
    fullTime: "Full Time",
    partTime: "Part Time",
    both: "Both",
}

const workLocationType = {
    wfo: 'Work From Office',
    wfh: 'Work From Home',
    fieldWork: 'Field Work',
    hybrid: 'Hybrid'
}

const salaryType = {
    fixed: 'Fixed Only',
    fixedAndIncentive: 'Fixed + Incentive',
    IncentiveOnly: 'Incentive Only',
}

const experienceType = {
    Any: "Any",
    experienceOnly: 'Experience Only',
    fresher: 'Fresher Only',
}

const genderTypes = {
    male: "Male",
    female: "Female",
    both: 'Both'
}

const languagesList = {
    marathi: "Marathi",
    hindi: 'Hindi',
    english: "English",
}

const gadgetsList = {
    smartphone: "Smartphone",
    laptop: 'Laptop',
    bike: "Bike",
}
export { educationCategories, JobTypes, workLocationType, salaryType, experienceType, genderTypes, languagesList,gadgetsList }