import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../global_state/GlobalStateContext';

const Preloader = () => {
    const { state, dispatch } = useGlobalState();
    const { isLoading } = state;

    useEffect(() => {
        const handleLoad = () => {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);
    // loaded
    const preloaderClass = isLoading ? '' : 'loaded';

    return (
        <div>
            <div id="preloader-wrap" className={preloaderClass}>
                <div className="spinner spinner-8">
                    {[...Array(12)].map((_, index) => (
                        <div key={index} className={`ms-circle${index + 1} ms-child`} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Preloader;
