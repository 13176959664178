import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function DeleteModel(props) {
    const { show, onClose } = props;

    const handleClose = () => {
        onClose();
    };

    const handleDelete = () => {
        // Perform delete action here
        console.log(`Deleting: ${props.itemName}`);
        handleClose();
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <h3 className="modal-title has-icon ms-icon-round">
                        {props.titleIcon && <i className={`flaticon-placeholder ${props.titleIcon} text-white`} />}
                        {props.title}
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    <p>{props.bodyText}</p>
                </Modal.Body>
                <Modal.Footer>
                    {props.learnMoreLink && (
                        <div className="modal-notice">
                            <Link to={props.learnMoreLink} className="btn-link">
                                Learn More
                            </Link>
                        </div>
                    )}
                    <Button variant="light" onClick={handleClose}>
                        {props.cancelButtonLabel || 'Cancel'}
                    </Button>
                    <Button variant="primary" onClick={handleDelete} className="shadow-none">
                        {props.confirmButtonLabel || 'Confirm'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

DeleteModel.propTypes = {
    titleIcon: PropTypes.string,
    title: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    learnMoreLink: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    onClose: PropTypes.func,
    itemName: PropTypes.string
};

export default DeleteModel;
