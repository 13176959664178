import axios from 'axios';
import Swal from 'sweetalert2';

const networkRequest = async (url, callback, isLoading, requestType = "get", formData = null) => {
  try {
    isLoading(true);
    let isTokenOk = true;
    let response;

    while (isTokenOk) {
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': localStorage.getItem('token'),
          'Refresh-Token': localStorage.getItem('refresh_token')
        }
      };

      if (requestType === "get") {
        response = await axios.get(url, headers);
      } else {
        response = await axios.post(url, formData, headers);
      }

      if (response.data['success']) {
        callback(response.data);
        isTokenOk = false;
      } else {
        if (response.data['message'] === "Token Expired") {
          localStorage.setItem('token', response.data['access-token']);
        } else {
          isTokenOk = false;
          Swal.fire({
            title: 'Oops',
            text: response.data['message'],
            icon: 'warning',
          });
        }
      }
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.message,
  });
    if (axios.isAxiosError(error) && error.response) {
      const { status } = error.response;

      if (status === 400 || status === 401) {
        // Bad Request
        // localStorage.clear();
        // return window.location.href = "/login";
      }
    }

    console.error('Error fetching data:', error);
    // console.log(error)
  } finally {
    isLoading(false);
  }
};

export { networkRequest };
