import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { networkRequest } from '../../utils/network_request';
import { apiAdmin } from '../../utils/urls';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import Swal from 'sweetalert2';

const JobCategory = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const { state, dispatch } = useGlobalState();
  const navigate = useNavigate();

  const [newCategoryImage, setNewCategoryImage] = useState(null);

  // ... existing useEffect and setData functions ...
  const isLoading = (status) => {
    dispatch({ type: 'SET_LOADING', payload: status });
  }

  
  const loadData = () => {
    networkRequest(apiAdmin.jobCategoriesGet, setData, isLoading);
  };  // Add 'isLoading' to the dependency array

  useEffect(() => {
    loadData();
  }, []);  // Add 'loadData' to the dependency array


  const setData = (data) => {
    setCategoriesList(data['data']);
  }

  const deleteJobCategory = (categories) => {
    Swal.fire({
      icon: 'warning',
      text: `Do you want to delete Job Category ${categories.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Close',
      preConfirm: () => {
        Swal.showLoading();
        return networkRequest(`${apiAdmin.jobCategoriesDelete}/${categories.id}`, '', isLoading)
          .then(() => {
            Swal.fire('Job Category deleted successfully!', '', 'success');
            // Update the executive list after successful deletion
            setCategoriesList(prevList => prevList.filter(item => item.id !== categories.id));
          })
          .catch(() => Swal.fire('Error deleting Job Category. Please try again.', '', 'error'));
      },
    });
  };

  const [formData, setFormData] = useState({
    name: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    networkRequest(apiAdmin.jobCategoriesAdd, (data) => {
      setShowRegisterModal(false);
      Swal.fire({
        title: "success",
        text: data['message'],
        icon: 'success',
        type: 'success'
      });
      loadData();
    }, isLoading, 'post', formDataToSend);
  }

  return (
    <div className="ms-content-wrapper m-1">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>JobCategory List</h6>
              </div>
              <div className="col-6">
              <button type="button" className="btn btn-primary has-icon float-right" onClick={() => {
                  setFormData(
                    {
                      ...formData,
                      id: '',
                      name: ''
                    }
                  )
                  setShowRegisterModal(true)
                }}>
                  <i className="fa fa-plus" aria-hidden="true"></i> Add JobCategory
                </button>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Job Category ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoriesList.map((categories) => (
                      <tr key={categories.id}>
                        <th scope="row">{categories.id}</th>
                        <td>{categories.name}</td>
                        <td>
                        <span onClick={() => {
                            setFormData({
                              ...formData,
                              name:categories.name,
                              id: categories.id
                            });
                            setShowRegisterModal(true)
                          }}><i className="fas fa-pencil-alt text-secondary" /></span>
                          <Link to="#" onClick={() => deleteJobCategory(categories)}>
                            <i className="far fa-trash-alt ms-text-danger" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showRegisterModal} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-primary">
          <h3 className="modal-title has-icon ms-icon-round text-white"><i className="flaticon-user bg-primary text-white" /> JobCategory</h3>
        </Modal.Header>
        <form method="post" onSubmit={handleSubmit}>
        <Modal.Body>
         
            <div class="ms-form-group has-icon">
              <label for="name">Name Of JobCategory</label>
              <input type="text" placeholder="Accountant, Clerk" class="form-control" id="name" name="name" value={formData.name}
                onChange={handleChange} required />
              <i class="material-icons">category</i>
            </div>
       
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light" onClick={() => setShowRegisterModal(false)}>Cancel</button>
          <button type="submit" className="btn btn-primary shadow-none">Submit</button>
        </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default JobCategory
