import React, { useEffect, useState } from 'react';
import { apiClient } from '../../utils/urls';
import { networkRequest } from '../../utils/network_request';
import { useGlobalState } from '../../global_state/GlobalStateContext';

const Transactions = () => {
    const [transactions, setTransactions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchTransactions();
    }, []);

    const fetchTransactions = () => {
        setIsLoading(true);
        var url = apiClient.clientGetTransactions;
        networkRequest(url, (data) => {
            console.log(data['data']['transactions']);
            setTransactions(data['data']['transactions']);

            setIsLoading(false);
        }, (status) => setIsLoading(status))
    }

    return (
        <div className="ms-content-wrapper p-2">
            <div className="ms-panel ms-panel-fh">
                <div className="table-responsive">
                    <table className="table table-hover thead-primary">
                        <thead>
                            <tr>
                                <th scope="col">Transaction Id</th>
                                <th scope="col">Coins</th>
                                <th scope="col">Balance</th>
                                <th scope="col">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="4">Loading...</td>
                                </tr>
                            ) : transactions && transactions.length > 0 ? (
                                transactions.map((coin) => (
                                    <tr key={coin.id}>
                                        <td>{coin.id.padStart(5, '0')}</td>
                                        <td>{coin.coins}</td>
                                        <td>{coin.total}</td>
                                        <td>{coin.coins >= 0 ? 'Credit' : 'Debit'}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No transactions available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Transactions;
