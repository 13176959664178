import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import { JobTypes, educationCategories, experienceType, gadgetsList, genderTypes, languagesList, salaryType, workLocationType } from '../../data/drop-down-data';
import { downloadCities, downloadEducations } from '../../utils/other_network_requests';
import { apiClient } from '../../utils/urls';
import { networkRequest } from '../../utils/network_request';
import { RadioButtonPostComponent } from '../../components/radio-check-box-button';
import Swal from 'sweetalert2';


const PostJobForm = () => {
    const location = useLocation();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [postId, setPostId] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const { state, dispatch } = useGlobalState();
    const [cityOptions, setCityOptions] = useState([]);
    const [degreeLabel, setDegreeLabel] = useState('');
    const [degreeOptions, setDegreeOptions] = useState([]);
    const [graduationOptions, setGraduationOptions] = useState([]);
    const [postGraduationOptions, setPostGraduationOptions] = useState([]);
    const [diplomaOptions, setDiplomaOptions] = useState([]);
    const [sscOptions, setSscOptions] = useState([]);
    const [hscOptions, setHscOptions] = useState([]);
    const depositOptions = {
        yes: "Yes",
        no: "No"
    }
    const [formData, setFormData] = useState({
        title: '',
        job_type: JobTypes.fullTime,
        is_night_shift: false,
        location_type: workLocationType.wfo,
        location_type_details: '',
        city_id: [],
        salary_type: salaryType.fixed,
        salary_min: 0,
        salary_max: 0,
        job_description: '',
        education_type: educationCategories.sscAndBelow,
        degree_type: [],
        gender: genderTypes.both,
        experience_type: experienceType.Any,
        experience_in: '',
        experience_years: 0,
        language_known: '',
        gadget_required: '',
        is_deposit_required: depositOptions.no,
        deposit_amount: 0

    });

    useEffect(() => {
        console.log(formData.degree_type)
    }, [formData.degree_type])

    const isLoading = (status) => {
        dispatch({ type: 'SET_LOADING', payload: status });
    }

    const handleDescriptionChange = (value) => {
        setFormData({ ...formData, job_description: value });
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear error when input changes
    };

    useEffect(() => {
        validateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])


    const validateForm = () => {
        const newErrors = {};

        // Validate Name
        if (!formData.title || formData.title.trim() === '' || formData.title.length < 3) {
            if (formData.title.length <= 3) {
                newErrors.title = "Job Title must be at least 3 characters";
            } else {
                newErrors.title = 'Name is required.';
            }
        }

        if (formData.location_type !== workLocationType.wfh) {
            if (formData.city_id.length === 0)
                newErrors.cityOptions = "Please Select 1  City at least.";
        }

        if (!formData.job_description || formData.job_description.trim() === '') {
            newErrors.job_description = "Job Description is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    };

    useEffect(() => {
        const updateLabels = () => {
            let options;
            let label;
            if (formData.education_type === educationCategories.graduation) {
                options = graduationOptions;
                label = 'Select Graduation';
            } else if (formData.education_type === educationCategories.pg) {
                options = postGraduationOptions;
                label = 'Select Post Graduation';
            } else if (formData.education_type === educationCategories.diploma) {
                options = diplomaOptions;
                label = 'Select Diploma';
            } else if (formData.education_type === educationCategories.hscPassed) {
                options = hscOptions;
                label = 'Select HSC/12th Courses';
            } else if (formData.education_type === educationCategories.sscAndBelow) {
                options = sscOptions;
                label = 'Select 10th Pass And Below';
            }
            setDegreeLabel(label)
            setDegreeOptions(options)
        }
        // setFormData((pre) => ({ ...pre, degree_type: '' })) //clear old selected
        updateLabels();
    }, [diplomaOptions, formData.education_type, graduationOptions, hscOptions, postGraduationOptions, sscOptions])



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            console.log(errors);
            return;
        }

        const submitData = new FormData();

        if ('city_id' in formData && Array.isArray(formData.city_id) && formData.city_id.length > 0) {
            if (workLocationType.wfh === formData.location_type) {
                setFormData((preData) => ({ ...preData, city_id: [] }))
            }
            // Convert city_id array to string
            const cityIdString = JSON.stringify(formData.city_id);
            // Add the string to FormData
            submitData.append('city_id', cityIdString);
        }

        if ('degree_type' in formData && Array.isArray(formData.degree_type) && formData.degree_type.length > 0) {
            // Convert degree_type array to string
            const degreeIdString = JSON.stringify(formData.degree_type);
            // Add the string to FormData
            submitData.append('degree_type', degreeIdString);
        }

        Object.keys(formData).forEach((key) => {
            if (key !== 'city_id' && key !== 'degree_type') {
                submitData.append(key, formData[key]);
            }
        });
        var url = apiClient.postCreate;

        if (postId != null)
            url = `${apiClient.postUpdate}/${postId}`

        networkRequest(url, (data) => {
            Swal.fire({ title: "Post Status", text: data['message'] })

            if (data.success) {
                let red = "/client/purchase_package";
                if (postId != null)
                    red = '/client/post-job-list';
                Swal.fire({ title: "Post Status", text: data['message'] });
                navigate(red, { state: { name: data['data'] } });
            } else {
                Swal.fire({ title: "Error", text: data.message, icon: "error" });
            }
        }, isLoading, 'post', submitData)
    };

    useEffect(() => {
        console.log('called')
        downloadEducations((postGraduationData, graduationData, diplomaData, hscOptions, sscOptions) => {
            setGraduationOptions(graduationData)
            setPostGraduationOptions(postGraduationData)
            setDiplomaOptions(diplomaData)
            setHscOptions(hscOptions)
            setSscOptions(sscOptions)
        }, isLoading)
        downloadCities(
            setCityOptions, isLoading)
        if (location.state) {
            getPostDataForEdit();
            setPostId(location.state.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPostDataForEdit = () => {
        networkRequest(`${apiClient.clientGetPost}/${location.state.id}`, (response) => {
            let data = response['data']['post'];
            setFormData({
                ...formData,
                title: data['title'],
                job_type: data['job_type'],
                is_night_shift: data['is_night_shift'],
                location_type: data['location_type'],
                location_type_details: data['location_type_details'],
                city_id: response['data']['cities'],
                salary_type: data['salary_type'],
                salary_min: data['salary_min'],
                salary_max: data['salary_max'],
                job_description: data['job_description'],
                education_type: data['education_type'],
                degree_type: response['data']['educations'],
                gender: data['gender'],
                language_known: data['language_known'].split(','),
                gadget_required: data['gadget_required'].split(','),
                experience_type: data['experience_type'],
                experience_in: data['experience_in'],
                experience_years: data['experience_years'],
                is_deposit_required: (data['deposit_amount'] === "0") ? depositOptions.no : depositOptions.yes,
                deposit_amount: data['deposit_amount'],
            });
        }, isLoading);
    }

    const handleCheckboxChanges = (e) => {
        const { name, value, checked } = e.target;

        if (name === 'languages') {
            const updatedLanguages = [...formData.language_known];

            if (checked) {
                updatedLanguages.push(value);
            } else {
                const index = updatedLanguages.indexOf(value);
                updatedLanguages.splice(index, 1);
            }

            setFormData({ ...formData, language_known: updatedLanguages });
        } else if (name === 'gadgets') {
            const updatedGadgets = [...formData.gadget_required];

            if (checked) {
                updatedGadgets.push(value);
            } else {
                const index = updatedGadgets.indexOf(value);
                updatedGadgets.splice(index, 1);
            }

            setFormData({ ...formData, gadget_required: updatedGadgets });
        }
    }

    return (
        <div className="ms-content-wrapper p-2">
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6>Job Details</h6>
                            <Link to={"/client/purchase_package"}> <button type="button" className="btn btn-primary has-icon float-right" style={{ 'margin-top': '-31px' }}>
                                <i className="fa fa-plus" aria-hidden="true"></i> Buy Package
                            </button></Link>
                        </div>

                        <div className="ms-panel-body">
                            <span className="ms-form-wizard ms-wizard-round style3-wizard">
                                <div className="content">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            {/* Contact */}
                                            <div className="col-md-12 ">
                                                <label htmlFor="validationCustomContact">Job Title</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                                        id="title"
                                                        name="title"
                                                        placeholder="Job Title"
                                                        value={formData.title}
                                                        aria-describedby="inputGroupPrepend"
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                {errors.title && <div className="text-danger bold">* {errors.title}</div>}
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="mr-2">Job Type</label>
                                                <div className="btn-group-toggle" data-toggle="buttons">
                                                    {Object.keys(JobTypes).map((item, index) => {
                                                        return <RadioButtonPostComponent
                                                            key={index}
                                                            name="job_type"
                                                            checked={formData.job_type === JobTypes[item]}
                                                            onChange={handleInputChange}
                                                            value={JobTypes[item]}
                                                            formValue={formData.job_type}
                                                        />
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className="form-check-label ms-checkbox-wrap ms-checkbox-primary">
                                                    <input className="form-check-input" type="checkbox" id="is_night_shift"
                                                        name="is_night_shift"
                                                        onChange={() => {
                                                            setFormData(prevState => ({ ...prevState, is_night_shift: !formData.is_night_shift }))
                                                        }}
                                                        checked={formData.is_night_shift}
                                                    /> <i className="ms-checkbox-check" />
                                                </label> <span> Is this a night shift job? </span>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label>Work Location Type</label>
                                                <div className="btn-group-toggle" data-toggle="buttons">
                                                    {Object.keys(workLocationType).map((item, index) => {
                                                        return <RadioButtonPostComponent
                                                            key={index}
                                                            name="location_type"
                                                            checked={formData.location_type === workLocationType[item]}
                                                            onChange={handleInputChange}
                                                            value={workLocationType[item]}
                                                            formValue={formData.location_type}
                                                        />
                                                    })}
                                                </div>
                                                {formData.location_type !== workLocationType.wfh && (
                                                    <div className="mt-3">
                                                        <label htmlFor="validationCustomCity">City</label>
                                                        <Select
                                                            className={`${errors.cityOptions ? 'is-invalid' : ''}`}
                                                            isMulti
                                                            options={cityOptions}
                                                            isSearchable
                                                            value={formData.city_id}
                                                            onChange={(selectedOptions) => handleInputChange({ target: { name: 'city_id', value: selectedOptions } })}
                                                            placeholder="Search for a city"
                                                        />
                                                        {errors.cityOptions && <div className="invalid text-danger">{errors.cityOptions}</div>}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-12 ">
                                                <label htmlFor="validationCustomContact">Location Details If Any</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.location_type_details ? 'is-invalid' : ''}`}
                                                        name="location_type_details"
                                                        placeholder="Location Details"
                                                        value={formData.location_type_details}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.location_type_details && <div className="invalid text-danger bold">*{errors.location_type_details}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="salary">Salary Type</label>
                                                <div className="btn-group-toggle" data-toggle="buttons">
                                                    {Object.keys(salaryType).map((item, index) => {
                                                        return <RadioButtonPostComponent
                                                            key={index}
                                                            name="salary_type"
                                                            checked={formData.salary_type === salaryType[item]}
                                                            onChange={handleInputChange}
                                                            value={salaryType[item]}
                                                            formValue={formData.salary_type}
                                                        />
                                                    })}
                                                </div>
                                                <div className='mt-3'>
                                                    {/* Show text box for fixed salary */}
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input
                                                                type="number"
                                                                className={`form-control`}
                                                                placeholder="Enter Minimum salary per Month"
                                                                name="salary_min"
                                                                min={0}
                                                                value={formData.salary_min}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-3">
                                                            <input
                                                                type="number"
                                                                className={`form-control`}
                                                                placeholder="Enter Maximum salary per Month"
                                                                name="salary_max"
                                                                min={0}
                                                                value={formData.salary_max}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Job Description */}
                                                <div className="mt-3" >
                                                    <label htmlFor="description">Job Description</label>
                                                    <ReactQuill
                                                        theme="snow"
                                                        name="job_description"
                                                        value={formData.job_description}
                                                        onChange={handleDescriptionChange}
                                                        placeholder="Enter job description here..."
                                                    />
                                                    {errors.job_description && <div className="invalid text-danger bold">* {errors.job_description}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* ********************* step two **************** */}
                                        <div className="form-row">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="education">Minimum Education</label>
                                                <div className="btn-group-toggle" data-toggle="buttons">
                                                    {Object.keys(educationCategories).map((item, index) => {
                                                        return <RadioButtonPostComponent
                                                            key={index}
                                                            name="education_type"
                                                            checked={formData.education_type === educationCategories[item]}
                                                            onChange={handleInputChange}
                                                            value={educationCategories[item]}
                                                            formValue={formData.education_type}
                                                        />
                                                    })}
                                                </div>
                                                <div className="mt-3">
                                                    <label htmlFor="graduateOptions">{degreeLabel}</label>
                                                    <Select
                                                        id="degree_type"
                                                        name="degree_type" // Ensure name attribute is set to the desired field name
                                                        options={degreeOptions}
                                                        isMulti
                                                        value={formData.degree_type}
                                                        onChange={(selectedOptions) => handleInputChange({ target: { name: 'degree_type', value: selectedOptions } })}
                                                    />
                                                </div>
                                            </div>

                                            {/* Total Experience Required */}
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="experience">Total Experience Required</label>
                                                <div className="btn-group-toggle" data-toggle="buttons">
                                                    {Object.keys(experienceType).map((item, index) => {
                                                        return <RadioButtonPostComponent
                                                            key={index}
                                                            name="experience_type"
                                                            checked={formData.experience === experienceType[item]}
                                                            onChange={handleInputChange}
                                                            value={experienceType[item]}
                                                            formValue={formData.experience_type}
                                                        />
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="experience">Total Experience In Field Of ?</label>
                                                <input
                                                    type="text"
                                                    className={`form-control`}
                                                    placeholder="Clark,accountant,plumber,php,html"
                                                    name="experience_in"
                                                    value={formData.experience_in}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="experience">Total Experience Of Years</label>
                                                <input
                                                    type="number"
                                                    className={`form-control`}
                                                    placeholder="Experience Year"
                                                    name="experience_years"
                                                    min={0}
                                                    value={formData.experience_years}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="language">Language Required</label>
                                                <ul className="ms-list d-flex">
                                                    {Object.keys(languagesList).map((item, index) => {
                                                        const isChecked = formData.language_known.includes(languagesList[item]); // Check if languagesList[item] is in languageForEdit
                                                        return (
                                                            <li className="ms-list-item pl-0" key={index}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input
                                                                        type="checkbox"
                                                                        name='languages'
                                                                        onChange={handleCheckboxChanges}
                                                                        value={languagesList[item]}
                                                                        checked={isChecked} // Set checked attribute based on the comparison
                                                                    />
                                                                    <i className="ms-checkbox-check" />
                                                                </label>
                                                                <span> {languagesList[item]} </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>

                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="language">Gadget Required</label>
                                                <ul className="ms-list d-flex">
                                                    {Object.keys(gadgetsList).map((item, index) => {
                                                        const isChecked = formData.gadget_required.includes(gadgetsList[item]); // Check if gadgetsList[item] is in gadgetForEdit
                                                        return (
                                                            <li className="ms-list-item pl-0" key={index}>
                                                                <label className="ms-checkbox-wrap">
                                                                    <input
                                                                        type="checkbox"
                                                                        name='gadgets'
                                                                        onChange={handleCheckboxChanges}
                                                                        value={gadgetsList[item]}
                                                                        checked={isChecked} // Set checked attribute based on whether the gadget is in gadgetForEdit
                                                                    />
                                                                    <i className="ms-checkbox-check" />
                                                                </label>
                                                                <span> {gadgetsList[item]} </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="gender">Gender Required</label>
                                                <div className="btn-group-toggle" data-toggle="buttons">
                                                    {Object.keys(genderTypes).map((item, index) => {
                                                        return <RadioButtonPostComponent
                                                            key={index}
                                                            name="gender"
                                                            checked={formData.gender === genderTypes[item]}
                                                            onChange={handleInputChange}
                                                            value={genderTypes[item]}
                                                            formValue={formData.gender}
                                                        />
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="deposit">Is there Any Deposit Required For This Job ?</label>
                                                <div className="btn-group-toggle" data-toggle="buttons">
                                                    {Object.keys(depositOptions).map((item, index) => {
                                                        return <RadioButtonPostComponent
                                                            key={index}
                                                            name="is_deposit_required"
                                                            checked={formData.is_deposit_required === depositOptions[item]}
                                                            onChange={handleInputChange}
                                                            value={depositOptions[item]}
                                                            formValue={formData.is_deposit_required}
                                                        />
                                                    })}
                                                </div>
                                            </div>
                                            {formData.is_deposit_required === depositOptions.yes && (
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="language">Deposit Amount</label>
                                                    <div className="form-group">
                                                        <input
                                                            type="number"
                                                            className="form-control float-right"
                                                            name="deposit_amount"
                                                            onChange={handleInputChange}
                                                            aria-describedby="amount"
                                                            placeholder="amount"
                                                            value={formData.deposit_amount}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form >
                                    {/* <Footer data={formData} error={errors} /> */}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PostJobForm;