const RadioButtonPostComponent = ({ name, value, checked, onChange, formValue }) => {
    return <label className={`btn btn-outline-primary m-1 ${formValue === value ? 'active' : ''} radiobtn`}>
        <input
            type="radio"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
        />
        {value}
    </label>
}

export { RadioButtonPostComponent }