import { Link, NavLink } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { Dropdown } from "react-bootstrap";
import { useGlobalState } from "../../global_state/GlobalStateContext";
import { networkRequest } from "../../utils/network_request";
import { apiClient } from "../../utils/urls";
import moment from 'moment';
import * as XLSX from 'xlsx';
import { formatDate } from "../../components/usefullFuntions";

const PostJobList = () => {
    const [postList, setPostList] = useState([]);
    const { state, dispatch } = useGlobalState();
    const [filter, setFilter] = useState('All');
    const isLoading = (state) => {
        dispatch({ type: 'SET_LOADING', payload: state });
    }
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = () => {
        networkRequest(apiClient.clientGetAllPost, (data) => {
            console.log(data['data']);
            setPostList(data['data']);
        }, isLoading);
    }

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    }

    const filterPosts = () => {
        if (filter === 'Pending') {
            return postList.filter(post => post.post_status === 'Pending');
        } else if (filter === 'Draft') {
            return postList.filter(post => post.post_status === 'Draft');
        } else if (filter === 'Approved') {
            return postList.filter(post => post.post_status === 'Approved');
        } else if (filter === 'Rejected') {
            return postList.filter(post => post.post_status === 'Rejected');
        }
        return postList; // Return all posts if filter is not applied
    }

    const filteredPosts = filterPosts();

    const exportToExcel = () => {
        // Extracting specific fields from applicantList
        const filteredApplicantList = postList.map(({ title }) => ({ title }));

        const ws = XLSX.utils.json_to_sheet(filteredPosts);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Post List');
        XLSX.writeFile(wb, 'post_list.xlsx');
    }

    const getBadgeClass = postStatus => {
        switch (postStatus) {
            case 'Pending':
                return 'badge-warning';
            case 'Draft':
                return 'badge-secondary';
            case 'Approved':
                return 'badge-success';
            case 'Rejected':
                return 'badge-danger';
            default:
                return 'badge-primary';
        }
    };


    return <div className="col-md-12">
        <div className="ms-panel">

            <div className="ms-panel-header row">
                <div className="col-3">
                    <h6>Latest Job Post</h6>
                </div>
                <div className="col-3">
                    <Link to="#" onClick={exportToExcel}><i className="fa fa-download mr-2" />Export to Excel</Link>
                </div>
                <div className="col-3">
                    <select className="form-control" value={filter} onChange={handleFilterChange}>
                        <option value="All">All</option>
                        <option value="Draft">Draft</option>
                        <option value="Pending">Pending</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                    </select>
                </div>
                <div className="col-3">
                    <Link to={"/client/post_job"}> <button type="button" className="btn btn-primary has-icon float-right">
                        <i className="fa fa-plus" aria-hidden="true"></i> Add Job Post
                    </button></Link>
                </div>
            </div>
            <div className="ms-panel-body">
                <div className="row">
                    {filteredPosts.map((item, i) => (
                        <div key={i} className="col-lg-6 col-md-6 col-sm-6">
                            <div className="ms-card">
                                <div className="ms-card-body">
                                    <div className="media fs-14">
                                        <Link to="/client/post-job-view" state={{ data: item }}>
                                            <div className="media-body">
                                                <h6>{item.title} <span className={`badge ${getBadgeClass(item.post_status)} ml-5`}>
                                                    {item.post_status}
                                                </span>
                                                    <p>{moment(new Date(item.created_at)).fromNow()}</p></h6>
                                                <Dropdown className="float-right">
                                                    <Dropdown.Toggle as={NavLink} className="p-0">
                                                        <i className="material-icons">more_vert</i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <ul className="dropdown-menu-right mb-0">
                                                            <li className="ms-dropdown-list">
                                                                {(item.post_status === 'Draft') &&
                                                                    <Link className="media p-2" to="/client/purchase_package" state={{ name: item.id }}>
                                                                        <div className="media-body">
                                                                            <span>Make Payment</span>
                                                                        </div>
                                                                    </Link>
                                                                }
                                                                <Link className="media p-2" to="/client/post_job" state={{ id: item.id }}>
                                                                    <div className="media-body">
                                                                        <span>Edit</span>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <div className="two-line-ellipsis"
                                                    dangerouslySetInnerHTML={{ __html: item.job_description }}
                                                />
                                                {(item.post_status === 'Rejected') &&
                                                    <p>Reason Of Rejection: {item.remark}</p>
                                                }
                                                {(item.post_status === 'Approved') &&
                                                    <p>Expiry Date: {formatDate(item.expiry_date)}</p>
                                                }
                                            </div>
                                        </Link>
                                    </div>

                                </div>
                                <div className="ms-card-footer text-disabled d-flex">
                                    <div className="ms-card-options"> <i class="fa fa-heart text-danger" title="Likes"></i> {item.likes}</div>
                                    <div className="ms-card-options"><i className="fa fa-eye" title="Views" /> {item.views}</div>
                                    <div className="ms-card-options"><Link to="/client/applicant-list" state={{ data: item.id }} title="Applicant"> <i className="fa fa-users" /> {item.applied}</Link></div>
                                    <div className="ms-card-options"><Link to="/client/bookmark-user-list" state={{ data: item.id }} title="Bookmark"><i className="fa fa-bookmark" /> {item.bookmarks}</Link></div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
}

export default PostJobList;