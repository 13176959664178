import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { networkRequest } from '../../utils/network_request';
import { apiAdmin, apiExecutive } from '../../utils/urls';
import { useGlobalState } from '../../global_state/GlobalStateContext';

const TransferBalance = () => {
  // Assuming GlobalStateProvider returns an array with state and dispatch
  const navigate = useNavigate();
  const { state, dispatch } = useGlobalState();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    'transfer_to': id,
    'coins': 0,
    'note': ''
  });

  const isLoading = (status) => {
    dispatch({ type: 'SET_LOADING', payload: status });
  };

  const [errors, setErrors] = useState({
    'coins': '',
    'note': ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
    // Clear errors when the user starts typing
    setErrors((data) => ({ ...data, [name]: '' }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate coins
    if (formData.coins <= 0) {
      setErrors((data) => ({ ...data, ['coins']: 'Coins must be greater than 0' }));
      return; // Exit early if there are errors
    }

    var url = apiAdmin.transferBalance;
    if (localStorage.getItem('user_type') === 'executive')
      url = apiExecutive.transferBalance;
    networkRequest(url, (data) => {
    Swal.fire({
        title: "Success",
        text: "Coin Transfer Successfully",
        icon: 'success'
      });
    }, isLoading, 'post', formData);
    navigate(-1);
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>Transfer Balance</h6>
              </div>
              <div className="col-6">
                {/* Additional content for the header if needed */}
              </div>
            </div>
            <div className="ms-panel-body">
              <form onSubmit={handleSubmit}>
                <div className="ms-form-group has-icon">
                  <label htmlFor="coin">Enter Amount</label>
                  <input type="number" placeholder="1000" className="form-control" min={1} id="coin" name="coins" onChange={handleChange} />
                  <i className="material-icons">attach_money</i>
                  <span className='text-danger'>{errors['coins']}</span>
                </div>
                <div className="ms-form-group has-icon">
                  <label htmlFor="note">Note</label>
                  <input type="text" className="form-control" id="note" name="note" onChange={handleChange} />
                  <i className="material-icons">note</i>
                  <span className='text-danger'>{errors['note']}</span>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
                <button className="btn btn-primary ml-1" onClick={(e) => {
                  navigate(-1);
                }}> <i className="fa fa-arrow-circle-left" aria-hidden="true"></i> Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransferBalance
