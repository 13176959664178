import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import { apiAdmin, apiExecutive } from '../../utils/urls';
import { networkRequest } from '../../utils/network_request';
import Swal from 'sweetalert2';



const Client = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [clientList, setClientList] = useState([]);
  const { state, dispatch } = useGlobalState();

  useEffect(() => {
    const isLoading = (status) => {
      dispatch({ type: 'SET_LOADING', payload: status });
    }
    var url = apiAdmin.clientGet;
    if (localStorage.getItem('user_type') === 'executive')
      url = apiExecutive.clientGet;
    networkRequest(url, setData, isLoading);
  }, []);
  const setData = (data) => {
    setClientList(data['data']);
  }

  const deleteClient = (client) => {
    Swal.fire({
      icon: 'warning',
      text: `Do you want to delete client ${client.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Close',
      preConfirm: () => {
        Swal.showLoading();
        const isLoading = (status) => dispatch({ type: 'SET_LOADING', payload: status });
        return networkRequest(`${apiAdmin.clientDeleteProfile}/${client.id}`, '', isLoading)
          .then(() => {
            Swal.fire('Client deleted successfully!', '', 'success');
            // Update the executive list after successful deletion
            setClientList(prevList => prevList.filter(item => item.id !== client.id));
          })
          .catch(() => Swal.fire('Error deleting Client. Please try again.', '', 'error'));
      },
    });
  };

  return (
    <div className="ms-content-wrapper m-1">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>Client List</h6>
              </div>
              <div className="col-6">
                <Link to={"/admin/client/register"}> <button type="button" className="btn btn-primary has-icon float-right">
                  <i className="fa fa-plus" aria-hidden="true"></i> Add Client
                </button></Link>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table id="clientTable" className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Client ID</th>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">Contact Number</th>
                      <th scope="col">Email</th>
                      <th scope="col">Coins</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientList.map((client) => (
                      <tr key={client.id}>
                        <th>{client.id}</th>
                        <td>{client.user_type.toUpperCase()}</td>
                        <td><Link to={`/admin/client/${client.id}`}>{client.name}</Link></td>
                        <td>{client.contact}</td>
                        <td>{client.email}</td>
                        <td>{client.coins ?? 0}</td>
                        <td>
                          <Link to={`/admin/client/${client.id}`}>
                            <i className="far fa-eye ms-text-danger" />
                          </Link>
                          <Link to="#" onClick={() => deleteClient(client)}>
                            <i className="far fa-trash-alt ms-text-danger" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Client
