import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { apiClient } from '../../utils/urls';
import { networkRequest } from '../../utils/network_request';
import * as XLSX from 'xlsx';
import { useGlobalState } from '../../global_state/GlobalStateContext';

const ApplicantList = () => {
    const [pendingList, setPendingList] = useState([]);
    const [shortlistedList, setShortlistedList] = useState([]);
    const [rejectedList, setRejectedList] = useState([]);
    const [activeTab, setActiveTab] = useState("tab1"); // Default active tab
    const location = useLocation();
    const { state, dispatch } = useGlobalState();
    const isLoading = (state) => {
        dispatch({ type: 'SET_LOADING', payload: state });
    }

    useEffect(() => {
        loadApplicants();
    }, [activeTab, location.state?.data]); // Reload data when activeTab or postId changes

    const loadApplicants = () => {
        const postId = location.state.data ;
        networkRequest(`${apiClient.applicantList}/${postId}/1`, (data) => {
            console.log('Data received:', data);
            if (data.success) {
                const { data: applicants } = data;
                const pending = applicants.filter(applicant => applicant.status === '0');
                const shortlisted = applicants.filter(applicant => applicant.status === '1');
                const rejected = applicants.filter(applicant => applicant.status === '2');
                setPendingList(pending);
                setShortlistedList(shortlisted);
                setRejectedList(rejected);
            } else {
                console.log(data.message);
                console.log("No candidate Found");
            }
        }, isLoading);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const exportToExcel = () => {
        let ListToExport = [];
        let fileName = '';
        switch (activeTab) {
            case 'tab1':
                ListToExport = pendingList;
                fileName = 'pending_list.xlsx';
                break;
            case 'tab2':
                ListToExport = shortlistedList;
                fileName = 'shortlisted_list.xlsx';
                break;
            case 'tab3':
                ListToExport = rejectedList;
                fileName = 'rejected_list.xlsx';
                break;
            default:
                break;
        }
    
        const filteredList = ListToExport.map(({ name, email, city, contact }) => ({ name, email, city, contact }));
        const ws = XLSX.utils.json_to_sheet(filteredList);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Applicant List');
        XLSX.writeFile(wb, fileName);
    }

    const shortlisted = () => {
        const postId = location.state.data ;
        networkRequest(`${apiClient.postShortlistReject}/${postId}/1`, (data) => {
            if (data) {
                console.log('Shortlisted');
            }
        }, isLoading);
    }



    return (
        <div className="col-md-12">
            <div className="ms-panel">
                <div className="ms-panel-header row">
                    <div className="col-6">
                        <h6>Applicant List</h6>
                    </div>
                    <div className="col-6">
                        <Link to="#" onClick={exportToExcel}><i className="fa fa-download mr-2" />Export to Excel</Link>
                    </div>
                </div>
                <div className="ms-panel-body">
                    <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
                        <Nav variant="tabs" className="nav nav-tabs tabs-bordered d-flex nav-justified mb-0">
                            <Nav.Item>
                                <Nav.Link eventKey="tab1">Pending</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab2">Shortlisted</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tab3">Rejected</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="tab1">
                            {pendingList.length > 0 ? (
                                pendingList.map((applicant, i) => (
                                    <div key={i} className="col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <div className="ms-card">
                                            <div className="ms-card-body">
                                                <div className="media fs-14">
                                                    <div className="media-body">
                                                        <h6>{applicant.name} <p>{applicant.email}</p></h6>
                                                        <p><b>Education :</b> MCA  from ABC University.     <b>Language Known  :  </b>Marathi , Hindi, English</p>
                                                        <p><b>Location</b> : {applicant.city}</p>
                                                        <span> <Link to="#" onClick={() => window.open('https://www.clickdimensions.com/links/TestPDFfile.pdf')} state={{ data: applicant }}><i className="fa fa-file-pdf mr-2" />See Resume</Link></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ms-card-footer text-disabled">
                                                <div className="row">
                                                    <div className="col-8"><button type="button" className="btn btn-outline-success" style={{ padding: 4, marginTop: 0 }}><i className="fa fa-phone" /> {applicant.contact}</button><button type="button" className="btn btn-outline-secondary ml-4" style={{ padding: 4, marginTop: 0 }}><i className="fa fa-comments ml-3" /> Chat Me </button></div>
                                                    <div className="col-2"><Link to="#"> <button type="button" className="btn btn-outline-success" style={{ padding: 4, marginTop: 0 }} onClick={shortlisted}>Shortlist</button></Link></div>
                                                    <div className="col-2"><Link to="/client/post-job-view" state={{ data: applicant }}>
                                                        <button type="button" className="btn btn-outline-danger" style={{ padding: 4, marginTop: 0 }}>Reject</button></Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))): (
                                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <div className="ms-card">
                                            <div className="ms-card-body">
                                                <p>No candidates found.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab2">
                            {shortlistedList.length > 0 ? (
                                shortlistedList.map((applicant, i) => (
                                    <div key={i} className="col-lg-12 col-md-12 col-sm-12 mt-2">
                                    <div className="ms-card">
                                        <div className="ms-card-body">
                                            <div className="media fs-14">
                                                <div className="media-body">
                                                    <h6>{applicant.name} <p>{applicant.email}</p></h6>
                                                    <p><b>Education :</b> MCA  from ABC University.     <b>Language Known  :  </b>Marathi , Hindi, English</p>
                                                    <p><b>Location</b> : {applicant.city}</p>
                                                    <span> <Link to="#" onClick={() => window.open('https://www.clickdimensions.com/links/TestPDFfile.pdf')} state={{ data: applicant }}><i className="fa fa-file-pdf mr-2" />See Resume</Link></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ms-card-footer text-disabled">
                                            <div className="row">
                                                <div className="col-8"><button type="button" className="btn btn-outline-success" style={{ padding: 4, marginTop: 0 }}><i className="fa fa-phone" /> {applicant.contact}</button><button type="button" className="btn btn-outline-secondary ml-4" style={{ padding: 4, marginTop: 0 }}><i className="fa fa-comments ml-3" /> Chat Me </button></div>
                                               <div className="col-2"><Link to="/client/post-job-view" state={{ data: applicant }}>
                                                    <button type="button" className="btn btn-outline-danger" style={{ padding: 4, marginTop: 0 }}>Reject</button></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))): (
                                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <div className="ms-card">
                                            <div className="ms-card-body">
                                                <p>No candidates found.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab3">
                            {rejectedList.length > 0 ? (
                                rejectedList.map((applicant, i) => (
                                     <div key={i} className="col-lg-12 col-md-12 col-sm-12 mt-2">

                                     <div className="ms-card">
                                         <div className="ms-card-body">
                                             <div className="media fs-14">
                                                 <div className="media-body">
                                                     <h6>{applicant.name} <p>{applicant.email}</p></h6>
                                                     <p><b>Education :</b> MCA  from ABC University.     <b>Language Known  :  </b>Marathi , Hindi, English</p>
                                                     <p><b>Location</b> : {applicant.city}</p>
                                                     <span> <Link to="#" onClick={() => window.open('https://www.clickdimensions.com/links/TestPDFfile.pdf')} state={{ data: applicant }}><i className="fa fa-file-pdf mr-2" />See Resume</Link></span>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="ms-card-footer text-disabled">
                                             <div className="row">
                                                 <div className="col-10"><button type="button" className="btn btn-outline-success" style={{ padding: 4, marginTop: 0 }}><i className="fa fa-phone" /> {applicant.contact}</button><button type="button" className="btn btn-outline-secondary ml-4" style={{ padding: 4, marginTop: 0 }}><i className="fa fa-comments ml-3" /> Chat Me </button></div>
                                                 <div className="col-2"><Link to="/client/applicant-list" state={{ data: applicant }}> <button type="button" className="btn btn-outline-success" style={{ padding: 4, marginTop: 0 }}>Shortlist</button></Link></div>
                                                 
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                )))  : (
                                    <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <div className="ms-card">
                                            <div className="ms-card-body">
                                                <p>No candidates found.</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </div>
    );
};

export default ApplicantList;
