import { domain } from "./domain";

const apiGeneral = {
    login: `${domain}/login`,
    otpSend: `${domain}/otp/send`,
    citiesGet: `${domain}/cities/get`,
    educationGet: `${domain}/education/get`,
    // login: `${domain}/login`,
};

const imageUrls = {
    executive: `${domain}/assets/executive/`,
    client: `${domain}/assets/client/`
}

const apiAdmin = {
    dashboard: `${domain}/admin/dashboard`,
    executiveGet: `${domain}/admin/executive/get`,
    executiveGetProfile: `${domain}/admin/executive/get`,
    executiveDeleteProfile: `${domain}/admin/executive/delete`,
    executiveAdd: `${domain}/admin/executive/add`,
    executiveEdit: `${domain}/admin/executive/edit`,
    executiveActiveInactive: `${domain}/admin/executive/active_inactive`,
    executiveRegisterUser: `${domain}/admin/executive/register-user`,

    // transactions
    transferBalance: `${domain}/admin/transaction/transfer`,

    //client
    clientGet: `${domain}/admin/client/get`,
    clientGetProfile: `${domain}/admin/client/get`,
    clientDeleteProfile: `${domain}/admin/client/delete`,
    clientActiveInactive: `${domain}/admin/client/active_inactive`,
    clientVerified: `${domain}/admin/client/verify`,

    //Job Categories
    jobCategoriesGet: `${domain}/admin/job_categories/get`,
    jobCategoriesAdd: `${domain}/admin/job_categories/add`,
    jobCategoriesDelete: `${domain}/admin/job_categories/delete`,

    // cities
    cityAdd: `${domain}/admin/city/add`,
    citiesGet: `${domain}/admin/cities/get`,

    // education
    educationsGet: `${domain}/admin/educations/get`,
    educationAdd: `${domain}/admin/education/add`,
    educationDelete: `${domain}/admin/education/delete`,

    //users
    userGet: `${domain}/admin/user/get`,
    userGetProfile: `${domain}/admin/user/get`,
    userAdd: `${domain}/admin/user/add`,
    userEdit: `${domain}/admin/user/edit`,
    userDeleteProfile: `${domain}/admin/user/delete`,
    userActiveInactive: `${domain}/admin/user/active_inactive`,
    userVerified: `${domain}/admin/user/verify`,

    //packages
    packagesGet: `${domain}/admin/packages/get`,
    packagesAdd: `${domain}/admin/packages/add`,
    packagesUpdate: `${domain}/admin/packages/update`,
    packagesDelete: `${domain}/admin/packages/delete`,

    //posts
    getAllPost:  `${domain}/admin/post/get`,
    approvePost:  `${domain}/admin/post/approve-post`,
   

    
    



};

const apiClient = {
    clientAdd: `${domain}/client/add`,
    clientEdit: `${domain}/client/edit`,
    clientGetProfile: `${domain}/client/get`,
    postCreate: `${domain}/client/post/create`,
    postUpdate: `${domain}/client/post/update`,
    clientGetDashboard: `${domain}/client/dashboard`,
    clientGetTransactions: `${domain}/client/transactions`,
    clientGetAllPost: `${domain}/client/get-all-post`,
    clientGetPost: `${domain}/client/post-get`, //pass id of post at the end
    unpublishPost:  `${domain}/client/post-publish`,
    applicantList:  `${domain}/client/post/applications`,
    packagesGetClient: `${domain}/client/packages/get`,
    postGetBookmarkList: `${domain}/client/get-like-bookmark-applied-list/bookmarks`,
    postShortlistReject: `${domain}/client/post/change-applicant-status`,
    postPurchasePackage: `${domain}/client/post/buy`,
    getConversation: `${domain}/client/message/get`,
    getChats: `${domain}/client/message/chats`,
    sendMessage: `${domain}/client/message/send`,


    

    


    

    
};

const apiExecutive = {
    dashboard: `${domain}/executive/dashboard`,
    executiveRegisterUser: `${domain}/executive/register-user`,

    //client
    clientGet: `${domain}/executive/client/get`,
    clientGetProfile: `${domain}/executive/client/get`,
    clientAdd: `${domain}/executive/client/add`,
    clientEdit: `${domain}/executive/client/edit`,

    citiesGet: `${domain}/executive/cities/get`,

    // transactions
    transferBalance: `${domain}/executive/transaction/transfer`,

    //users
    userGet: `${domain}/executive/user/get`,
    userGetProfile: `${domain}/executive/user/get`,
    userAdd: `${domain}/executive/user/add`,
    userEdit: `${domain}/executive/user/edit`,
};

const apiUser = {

};


export { apiAdmin, apiClient, apiExecutive, apiUser, apiGeneral, imageUrls };
