import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { networkRequest } from '../../utils/network_request';
import { apiAdmin } from '../../utils/urls';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import Swal from 'sweetalert2';

const Packages = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const { dispatch } = useGlobalState();
  const [formError] = useState({});
  const [packages, setPackagesList] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    amount: '',
    validity: '',
    whatsapp: false,
    story: false,
    cost_price: '',
    sale_price: '',
  });

  const handleInputs = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };


  const isLoading = (status) => {
    dispatch({ type: 'SET_LOADING', payload: status });
  };  // Add 'dispatch' to the dependency array


  const loadData = () => {
    networkRequest(apiAdmin.packagesGet, setData, isLoading);
  };  // Add 'isLoading' to the dependency array


  useEffect(() => {
    loadData();
  }, []);  // Add 'loadData' to the dependency array

  const setData = (data) => {
    setPackagesList(data['data']);
  }

  const deletePackages = (pkg) => {
    Swal.fire({
      icon: 'warning',
      text: `Do you want to delete Package ${pkg.title}?`,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Close',
      preConfirm: () => {
        Swal.showLoading();
        const isLoading = (status) => dispatch({ type: 'SET_LOADING', payload: status });
        return networkRequest(`${apiAdmin.packagesDelete}/${pkg.id}`, '', isLoading)
          .then(() => {
            Swal.fire('Package deleted successfully!', '', 'success');
            // Update the executive list after successful deletion
            setPackagesList(prevList => prevList.filter(item => item.id !== pkg.id));
          })
          .catch(() => Swal.fire('Error deleting Packages. Please try again.', '', 'error'));
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
      if (key === 'whatsapp' || key === 'story') {
        formDataToSend.append(key, formData[key] ? '1' : '0'); // Convert boolean to 1 or 0
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
    const requestUrl = formData.id ? `${apiAdmin.packagesUpdate}/${formData.id}` : apiAdmin.packagesAdd;
    console.log(formDataToSend);
    console.log(formData.story);

    networkRequest(requestUrl, (data) => {
      setShowRegisterModal(false);
      Swal.fire({
        title: "success",
        text: data['message'],
        icon: 'success',
        type: 'success'
      });
      loadData();
    }, isLoading, 'post', formDataToSend);
  }


  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>Packages List</h6>
              </div>
              <div className="col-6">
                <button type="button" className="btn btn-primary has-icon float-right" onClick={() => {
                  setFormData(
                    {
                      ...formData,
                      id: '',
                      title: '',
                      cost_price: '',
                      sale_price: '',
                      validity: '',
                      whatsapp: '',
                      story: '',

                    }
                  )
                  setShowRegisterModal(true)
                }}>
                  <i className="fa fa-plus" aria-hidden="true"></i> Add Packages
                </button>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">Package ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Cost Price</th>
                      <th scope="col">Sale Price</th>
                      <th scope="col">Validity</th>
                      <th scope="col">whatsapp</th>
                      <th scope="col">story</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {packages.map((pkg) => (
                      <tr key={pkg.id}>
                        <th scope="row">{pkg.id.padStart(3, '0')}</th>
                        <td>{pkg.title}</td>
                        <td>{pkg.cost_price}</td>
                        <td>{pkg.sale_price}</td>
                        <td>{pkg.validity}</td>
                        <td>{pkg.whatsapp}</td>
                        <td>{pkg.story}</td>
                        <td>
                          <span onClick={() => {
                            setFormData({
                              ...formData,
                              id: pkg.id,
                              title: pkg.title,
                              cost_price: pkg.cost_price,
                              sale_price: pkg.sale_price,
                              validity: pkg.validity,
                              whatsapp: pkg.whatsapp === '1', // Convert '1' to true, '0' to false
                              story: pkg.story === '1', // Convert '1' to true, '0' to false
                            });
                            setShowRegisterModal(true)
                          }}><i className="fas fa-pencil-alt text-secondary" /></span>
                          <Link to="#" onClick={() => deletePackages(pkg)}>
                            <i className="far fa-trash-alt ms-text-danger" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showRegisterModal} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-primary">
          <h3 className="modal-title has-icon ms-icon-round text-white"><i className="fa fa-building bg-primary text-white" /> Packages</h3>
        </Modal.Header>
        <form method="post" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="ms-form-group has-icon">
              <label htmlFor="title">Name Of Package</label>
              <input type="text" placeholder="Title" className="form-control" id="title" name="title" value={formData.title} onChange={handleInputs} required />
              <p className='text-danger text-right mr-3'>{formError.title && (formError.title)}</p>
            </div>

            <div className="ms-form-group has-icon">
              <label htmlFor="validity">Validity in Days</label>
              <input type="number" placeholder="Validity In Days" className="form-control" id="validity" name="validity" value={formData.validity} onChange={handleInputs} required />
              <p className='text-danger text-right mr-3'>{formError.validity && (formError.validity)}</p>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-check-label ms-checkbox-wrap ms-checkbox-primary">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="whatsapp"
                  name="whatsapp"
                  onChange={() => {
                    setFormData(prevState => ({ ...prevState, whatsapp: !formData.whatsapp }))
                  }}
                  checked={formData.whatsapp}
                />
                <i className="ms-checkbox-check" />
              </label>
              <span> Whatsapp Status </span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-check-label ms-checkbox-wrap ms-checkbox-primary">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="story"
                  name="story"
                  onChange={() => {
                    setFormData(prevState => ({ ...prevState, story: !formData.story }))
                  }}
                  checked={formData.story}
                />
                <i className="ms-checkbox-check" />
              </label>
              <span> Story </span>
            </div>

            <div className="ms-form-group has-icon">
              <label htmlFor="cost_price">Cost Price</label>
              <input type="number" placeholder="Cost Price" className="form-control" id="cost_price" name="cost_price" value={formData.cost_price} onChange={handleInputs} required />
              <p className='text-danger text-right mr-3'>{formError.cost_price && (formError.cost_price)}</p>
            </div>
            <div className="ms-form-group has-icon">
              <label htmlFor="sale_price">Sell Price</label>
              <input type="number" placeholder="Sale Price" className="form-control" id="sale_price" name="sale_price" value={formData.sale_price} onChange={handleInputs} required />
              <p className='text-danger text-right mr-3'>{formError.sale_price && (formError.sale_price)}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={() => setShowRegisterModal(false)}>Cancel</button>
            <button type="submit" className="btn btn-primary shadow-none">Submit</button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default Packages
