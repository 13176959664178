import React, { useCallback, useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import customerImg from '../../../assets/img/avatar.png'
import { useGlobalState } from '../../../global_state/GlobalStateContext';
import { networkRequest } from '../../../utils/network_request';
import { apiAdmin, apiExecutive, imageUrls } from '../../../utils/urls';
import { domain } from '../../../utils/domain';
import Swal from 'sweetalert2';

function ClientProfile() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state, dispatch } = useGlobalState();
    const [userData, setUserData] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const isLoading = useCallback((status) => {
        dispatch({ type: 'SET_LOADING', payload: status });
    }, [dispatch]);

    useEffect(() => {
        var url = apiAdmin.clientGetProfile;
        if (localStorage.getItem('user_type') === 'executive')
            url = apiExecutive.clientGetProfile;
        networkRequest(`${url}/${id}`, setData, isLoading);
    }, [id]);



    const setData = (data) => {
        setUserData(data['data']);
        console.log(data['data']);
        setIsActive(data['data'] ? (data['data']['status']['is_active'] === "1") ? true : false : false);

        setIsVerified(data['data'] ? (data['data']['profile']['is_verified'] === "1") ? true : false : false);
    }

    const handleActiveInactive = useCallback(
        () => {
            var id = userData['status']['id'];
            var status = (isActive) ? "0" : "1";
            networkRequest(`${apiAdmin.clientActiveInactive}/${id}/${status}`, (resp) => {
                Swal.fire({
                    title: 'Success',
                    text: (!isActive) ? "Activated" : "Inactive",
                    icon: 'success',
                    type: 'success'
                });
                setIsActive(!isActive);
            }, isLoading);
        }, [isActive]
    );



    const handleVerified = useCallback(() => {
        var id = userData['profile']['id'];
        var status = (isVerified) ? "0" : "1";

        networkRequest(`${apiAdmin.clientVerified}/${id}/${status}`, (resp) => {
            Swal.fire({
                title: 'Success',
                text: (!isVerified) ? "Verified" : "Unverified",
                icon: 'success',
                type: 'success'
            });
            console.log(!isVerified);
            setIsVerified(!isVerified);
        }, isLoading)
    }, [isVerified]);


    return (
        <div className="ms-content-wrapper m-1">
            <div className="ms-profile-overview">
                <div className="ms-profile-cover">
                    <img className="ms-profile-img" src={userData ? `${domain}/assets/client/${userData['profile']['profile_photo']}` : customerImg} alt="people" />
                    <div className="ms-profile-user-info">
                        <h4 className="ms-profile-username text-white">{userData ? userData['profile']['name'] : "loading ..."} <i className="fa fa-check-circle text-success"></i> </h4>
                        <h2 className="ms-profile-role">{userData ? userData['profile']['type'] : "loading ..."}</h2>
                    </div>
                    <div className="ms-profile-user-buttons">
                        <button className="btn btn-primary mr-1" onClick={(e) => {
                            navigate(-1);
                        }}> <i className="fa fa-arrow-circle-left" aria-hidden="true"></i> Back</button>
                        <Link to={`/admin/transfer_balance/${userData ? userData['profile']['credential_id'] : 0}`} className="btn btn-primary"> <i className="material-icons">add</i> Add Coins</Link>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-xl-5 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body">
                            <ul className="ms-profile-stats">
                                <li>
                                    <h3 className="ms-count">50</h3>
                                    <span>Job Post</span>
                                </li>
                                <li>
                                    <h3 className="ms-count">4k</h3>
                                    <span>Followers</span>
                                </li>
                                <li>
                                    <h3 className="ms-count">{userData && userData['coins'] && userData['coins'][0] ? userData['coins'][0]['total'] : 0}</h3>
                                    <span>Coins</span>
                                </li>
                            </ul>
                            <h2 className="section-title">Client Profile <Link to={`/admin/client/edit/${id}`}> (Edit <i className="fas fa-pencil-alt text-secondary" />)</Link></h2>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Client Status (<span className={(isActive) ? "text-success" : "text-danger"}> {(isActive) ? "Active" : "Inactive"} </span>)</th>
                                        <td>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={isActive} onChange={handleActiveInactive} />
                                                <span className="ms-switch-slider ms-switch-success round" />
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Client Verified (<span className={(isVerified) ? "text-success" : "text-danger"}> {(isVerified) ? "Verified" : "Unverified"} </span>)</th>
                                        <td>
                                            <label className="ms-switch">
                                                <input type="checkbox" checked={isVerified} onChange={handleVerified} />
                                                <span className="ms-switch-slider ms-switch-success round" />
                                            </label>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th scope="row">Client Registration Id</th>
                                        <td> #{userData ? userData['profile']['id'].padStart(4, '0') : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Full Name</th>
                                        <td style={{ whiteSpace: 'pre-line' }}>{userData ? userData['profile']['name'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Client Type</th>
                                        <td>{userData ? userData['profile']['type'] : "loading ..."}</td>
                                    </tr>

                                    <tr>
                                        <th scope="row">Contact</th>
                                        <td>{userData ? userData['profile']['contact'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Alternate Contact</th>
                                        <td>{userData ? userData['profile']['alternate_contact'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Email</th>
                                        <td>{userData ? userData['profile']['email'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Address</th>
                                        <td style={{ whiteSpace: 'pre-line' }}>{userData ? userData['profile']['address'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Website</th>
                                        <td>{userData ? userData['profile']['website'] : "loading ..."}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">About</th>
                                        <td style={{ whiteSpace: 'pre-line' }}>{userData ? userData['profile']['overview'] : "loading ..."}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="ms-profile-skills">
                                <h2 className="section-title">Available Jobs</h2>
                                <ul className="ms-skill-list">
                                    <li className="ms-skill">Web Design</li>
                                    <li className="ms-skill">Development</li>
                                    <li className="ms-skill">Interface Design</li>
                                    <li className="ms-skill">Illustration</li>
                                    <li className="ms-skill">Brand Design</li>
                                    <li className="ms-skill">Adobe</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-7 col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body">
                            <Tab.Container defaultActiveKey="tab1">
                                <Nav variant="tabs" className="nav nav-tabs tabs-bordered d-flex nav-justified mb-4">

                                    <Nav.Item>
                                        <Nav.Link eventKey="tab1">Job Posted</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab2">Followers</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab3">Coin Transactions</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="tab1">
                                        <div className="table-responsive">
                                            <table className="table table-hover thead-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Likes</th>
                                                        <th scope="col">Applied</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <div className="table-responsive">
                                            <table className="table table-hover thead-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Contact</th>
                                                        <th scope="col">Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab3">
                                        <div className="table-responsive">
                                            <table className="table table-hover thead-primary">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Transaction Id</th>
                                                        <th scope="col">Coins</th>
                                                        <th scope="col">Balance</th>
                                                        <th scope="col">Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userData && userData['coins'] && (userData['coins'].map((coin) => (
                                                        <tr key={coin.id}>
                                                            <td>{coin.id.padStart(5, '0')}</td>
                                                            <td>{coin.coins}</td>
                                                            <td>{coin.total}</td>
                                                            <td>{coin.coins >= 0 ? 'Credit' : 'Debit'}</td>
                                                        </tr>
                                                    )))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ClientProfile;
