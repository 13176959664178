import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiAdmin } from '../../utils/urls';
import { networkRequest } from '../../utils/network_request';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import Swal from 'sweetalert2';

const Executive = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [executiveList, setExecutiveList] = useState([]);
  const { state, dispatch } = useGlobalState();



  useEffect(() => {

    const isLoading = (status) => {
      dispatch({ type: 'SET_LOADING', payload: status });
    }
    networkRequest(apiAdmin.executiveGet, setData, isLoading);
  }, []);

  const setData = (data) => {
    setExecutiveList(data['data']);
  }

  const handleDeleteModal = (executive) => {
    Swal.fire({
      icon: 'warning',
      text: `Do you want to delete executive ${executive.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Close',
      preConfirm: () => {
        Swal.showLoading();
        const isLoading = (status) => dispatch({ type: 'SET_LOADING', payload: status });
        return networkRequest(`${apiAdmin.executiveDeleteProfile}/${executive.id}`, (r) => {
          Swal.fire(r['message'], '', 'success');
          setExecutiveList(prevList => prevList.filter(item => item.id !== executive.id));
        }, isLoading);
      },
    });
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>Executive List</h6>
              </div>
              <div className="col-6">
                <Link to={"/admin/executive/add"}> <button type="button" className="btn btn-primary has-icon float-right">
                  <i className="fa fa-plus" aria-hidden="true"></i> Add Executive
                </button></Link>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Contact Number</th>
                      <th scope="col">Email</th>
                      <th scope="col">Date Of Birth</th>
                      <th scope="col">Coins</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {executiveList.map((executive) => (
                      <tr key={executive.id}>
                        <th>#{String(executive.id).padStart(4, '0')}</th>
                        <td><Link to={`/admin/executive/${executive.id}`}>{executive.name}</Link></td>
                        <td>{executive.contact}</td>
                        <td>{executive.email}</td>
                        <td>{executive.date_of_birth}</td>
                        <td>{executive.coins ?? 0}</td>
                        <td className={executive.is_active == 1 ? "text-success" : "text-danger"}>
                          {executive.is_active == 1 ? "Active" : "Inactive"}
                        </td>
                        <td>
                          <Link to={`/admin/executive/${executive.id}`}>
                            <i className="far fa-eye ms-text-danger" />
                          </Link>
                          <Link to={`/admin/executive/edit/${executive.id}`}><i className="fas fa-pencil-alt text-secondary" /></Link>
                          <Link to="#" onClick={() => handleDeleteModal(executive)}>
                            <i className="far fa-trash-alt ms-text-danger" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Executive
