import React, { useEffect } from 'react'
import TopNavigation from '../Admin/layouts/TopNavigation'
import SideNavigationClient from '../Client/SideNavigationClient'
import { Navigate, useNavigate } from 'react-router-dom';

const ClientTemplate = ({ page: PageComponent }) => {
    const isLoggedIn = localStorage.getItem('logged_in');
    const userType = localStorage.getItem('user_type');
    const navigate = useNavigate();
    const profileStatus = localStorage.getItem('profile_status');
    useEffect(() => {
        const currentPage = window.location.pathname;
        if (profileStatus === '0' && currentPage !== '/client/profile-edit') {
            navigate('/client/profile-edit');
        }
    }, [navigate, profileStatus])
    if (isLoggedIn && (userType === 'client')) {
        return (
            <div className="ms-body ms-aside-left-open ms-primary-theme">
                <SideNavigationClient />
                <main className="body-content">
                    <TopNavigation />
                    <PageComponent />
                </main>
            </div>
        )
    } else {
        return (<Navigate to="/login" />);
    }
}

export default ClientTemplate
