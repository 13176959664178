import { educationCategories } from "../data/drop-down-data";
import { networkRequest } from "./network_request";
import { apiGeneral } from "./urls";

const downloadCities = (setOptions, isLoading) => {
    networkRequest(apiGeneral.citiesGet, (data) => {
        const modifiedCityOptions = data['data'].map(city => ({
            value: city.id,
            label: `${city.name}-${city.state}`,
        }));
        setOptions(modifiedCityOptions);
    }, isLoading);
};

const downloadEducations = (setOptions, isLoading) => {
    networkRequest(apiGeneral.educationGet, (data) => {
        const education = data['data'];

        // Declare arrays for each category
        let graduationData = [];
        let postGraduationData = [];
        let diplomaData = [];
        let hscData = [];
        let sscData = [];

        // Loop through education data and categorize
        education.forEach(item => {
            const mappedItem = { value: item.id, label: item.name };

            if (item.category === educationCategories.graduation) {
                graduationData.push(mappedItem);
            } else if (item.category === educationCategories.pg) {
                postGraduationData.push(mappedItem);
            } else if (item.category === educationCategories.diploma) {
                diplomaData.push(mappedItem);
            } else if (item.category === educationCategories.hscPassed) {
                hscData.push(mappedItem);
            } else if (item.category === educationCategories.sscAndBelow) {
                sscData.push(mappedItem);
            }
        });

        setOptions(postGraduationData, graduationData, diplomaData, hscData, sscData);
    }, isLoading);
};



export { downloadCities, downloadEducations }