import React, { useEffect, useState } from 'react';
import Scrollbar from 'react-perfect-scrollbar'
import { useGlobalState } from '../../global_state/GlobalStateContext';
import { networkRequest } from '../../utils/network_request';
import { apiClient } from '../../utils/urls';

const ChatBox = () => {

    const [conversationUserList, setConversationList] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [receiverId, setReceiverId] = useState([]);
    const [userName, setUserName] = useState([]);

    const [formDataToSend, setFormDataToSend] = useState({ 'receiver_id': '', 'message': '' });
    const { dispatch } = useGlobalState();
    const isLoading = (state) => {
        dispatch({ type: 'SET_LOADING', payload: state });
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const loadData = () => {
        networkRequest(apiClient.getConversation, (data) => {
            setConversationList(data['data']);
            // Automatically load chat for the first user
            if (data['data'].length > 0) {
                const firstUser = data['data'][0];
                loadChat(firstUser.id, firstUser.username, firstUser.user_id);
                // Set the username and user ID for the first user in the right chat box
                setUserName(firstUser.username);
                setReceiverId(firstUser.user_id);
            }
        }, isLoading);
    }

    const loadChat = (messageId, username, user_id) => {
        networkRequest(`${apiClient.getChats}/${messageId}`, (data) => {
            const reversedChatList = data['data'].reverse();
            setChatList(reversedChatList);
            setReceiverId(user_id);
            setUserName(username);

        }, isLoading);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        form.append('receiver_id', receiverId);
        form.append('message', formDataToSend.message);
        networkRequest(apiClient.sendMessage, (data) => {
            setFormDataToSend({ ...formDataToSend, message: '' });
            loadData();
        }, isLoading, 'post', form);
    }


    const handleChange = (e) => {
        setFormDataToSend({
            ...formDataToSend,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="ms-content-wrapper">
            <div className="row">
                {/* Chat Sidebar */}
                <div className="col-xl-4 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body py-3 px-0">
                            <div className="ms-chat-container">
                                <div className="ms-chat-header px-3">
                                    <form className="ms-form my-3" method="post">
                                        <div className="ms-form-group my-0 mb-0 has-icon fs-14">
                                            <input type="search" className="ms-form-input w-100" name="search" placeholder="Search for People and Groups" /> <i className="flaticon-search text-disabled" />
                                        </div>
                                    </form>
                                </div>
                                <div className="ms-chat-body">
                                    <Scrollbar className="ms-scrollable">
                                        <ul>
                                            {conversationUserList.map((user, index) => (
                                                <div key={index} onClick={() => loadChat(user.id, user.username, user.user_id)}>
                                                    <li className="ms-chat-user-container ms-open-chat ms-deletable p-3 media clearfix">
                                                        <div className="media-body ms-chat-user-info mt-1">
                                                            <h6>{user.username}</h6>
                                                        </div>
                                                    </li>
                                                </div>
                                            ))}
                                        </ul>
                                    </Scrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Chat Body */}
                <div className="col-xl-8 col-md-12">
                    <div className="ms-panel ms-chat-conversations ms-chat-page ms-widget">
                        <div className="ms-panel-header">
                            <div className="ms-chat-header justify-content-between">
                                <div className="ms-chat-user-container media clearfix">
                                    <div className="media-body ms-chat-user-info mt-1">
                                        <h6>{userName}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Scrollbar className="ms-panel-body ms-scrollable">
                            {chatList.map((chat, index) => (
                                <div key={index} className={`ms-chat-bubble ms-chat-message ${chat.sender_id === '2' ? 'ms-chat-outgoing' : 'ms-chat-incoming'} media clearfix`}>
                                    <div className="media-body">
                                        <div className="ms-chat-text">
                                            <p>{chat.message}</p>
                                        </div>
                                        <p className="ms-chat-time">{chat.created_at}</p>
                                    </div>
                                </div>
                            ))}
                        </Scrollbar>
                        <div className="ms-panel-footer pt-0">
                            <form onSubmit={handleSubmit}>
                                <div className="ms-chat-textbox">
                                    <ul className="ms-list-flex mb-0">
                                        <li className="ms-chat-input">
                                            <input type="text" name="message" value={formDataToSend.message} onChange={handleChange} placeholder="Enter Message" />
                                            <input type="hidden" name="receiver_id" value={receiverId} />

                                        </li>
                                        <li>
                                            <button type="submit">
                                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatBox;
