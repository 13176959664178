import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { networkRequest } from '../../../utils/network_request';
import { apiAdmin, imageUrls } from '../../../utils/urls';
import Swal from 'sweetalert2';
import { useGlobalState } from '../../../global_state/GlobalStateContext';
import Select from 'react-select';

export default function ExecutiveAdd() {
  const { id } = useParams();
  const navigate = useNavigate();
  const MAX_FILE_SIZE = 500 * 1024; // 500KB
  const { state, dispatch } = useGlobalState();
  const [profile, setProfile] = useState(null);
  const [errors, setErrors] = useState({});
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);


  const isLoading = (status) => {
    dispatch({ type: 'SET_LOADING', payload: status });
  }

  const [formData, setFormData] = useState({
    city_id: '',
    name: '',
    contact: '',
    email: '',
    address: '',
    alternativeNumber: '',
    date_of_birth: '',
  });

  const [fileData, setFileData] = useState({
    passport_size_photo: null,
    id_proof_link: null,
    address_proof_link: null,
    additional_doc_link: null,
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' }); // Clear error when input changes
  };

  const handleFileChange = (e) => {
    setFileData({ ...fileData, [e.target.name]: e.target.files[0] });
    setErrors({ ...errors, [e.target.name]: '' }); // Clear error when input changes
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate city
    if (!formData.city_id || formData.city_id.trim() === '') {
      newErrors.city_id = 'city is required.';
    }

    // Validate Name
    if (!formData.name || formData.name.trim() === '') {
      newErrors.name = 'Name is required.';
    }

    // Validate Contact
    if (!formData.contact || formData.contact.trim() === '' || isNaN(formData.contact) || formData.contact.length < 10) {
      newErrors.contact = 'Please enter a valid 10-digit contact number.';
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailRegex.test(formData.email.trim())) {
      newErrors.email = 'Please enter a valid email address.';
    }

    // Validate Address
    if (!formData.address || formData.address.trim() === '') {
      newErrors.address = 'Address is required.';
    }

    // Validate Alternative Number
    if (
      formData.alternativeNumber &&
      (isNaN(formData.alternativeNumber) || formData.alternativeNumber.length !== 10)
    ) {
      newErrors.alternativeNumber = 'Please enter a valid 10-digit alternative number.';
    }

    // Validate Date of Birth
    // You may want to add more specific validation based on your requirements
    if (!formData.date_of_birth || formData.date_of_birth.trim() === '') {
      newErrors.date_of_birth = 'Date of Birth is required.';
    }

    if (id) {
      // Validate File Inputs
      if (fileData.passport_size_photo) {
        if (!fileData.passport_size_photo.type.startsWith('image/')) {
          newErrors.passport_size_photo = 'Please upload a valid image file for Password Size Photo.';
        } else if (fileData.passport_size_photo.size > MAX_FILE_SIZE) {
          newErrors.passport_size_photo =
            'File size exceeds the maximum allowed size (500KB) for Password Size Photo.';
        }
      }

      // Validate File Inputs
      if (fileData.id_proof_link) {
        if (!fileData.id_proof_link.type.startsWith('image/')) {
          newErrors.id_proof_link = 'Please upload a valid image file for Id card Photo.';
        } else if (fileData.id_proof_link.size > MAX_FILE_SIZE) {
          newErrors.id_proof_link =
            'File size exceeds the maximum allowed size (500KB) for Id card Photo.';
        }
      }

      // Validate File Inputs
      if (fileData.address_proof_link) {
        if (!fileData.address_proof_link.type.startsWith('image/')) {
          newErrors.address_proof_link = 'Please upload a valid image file for Address Proof.';
        } else if (fileData.address_proof_link.size > MAX_FILE_SIZE) {
          newErrors.address_proof_link =
            'File size exceeds the maximum allowed size (500KB) for Address Proof.';
        }
      }
    } else {
      // Validate File Inputs
      if (!fileData.passport_size_photo) {
        newErrors.passport_size_photo = 'Password Size Photo is required.';
      } else if (!fileData.passport_size_photo.type.startsWith('image/')) {
        newErrors.passport_size_photo = 'Please upload a valid image file for Password Size Photo.';
      } else if (fileData.passport_size_photo.size > MAX_FILE_SIZE) {
        newErrors.passport_size_photo =
          'File size exceeds the maximum allowed size (500KB) for Password Size Photo.';
      }

      // Validate File Inputs
      if (!fileData.id_proof_link) {
        newErrors.id_proof_link = 'Id card Photo is required.';
      } else if (!fileData.id_proof_link.type.startsWith('image/')) {
        newErrors.id_proof_link = 'Please upload a valid image file for Id card Photo.';
      } else if (fileData.id_proof_link.size > MAX_FILE_SIZE) {
        newErrors.id_proof_link =
          'File size exceeds the maximum allowed size (500KB) for Id card Photo.';
      }

      // Validate File Inputs
      if (!fileData.address_proof_link) {
        newErrors.address_proof_link = 'Address Proof is required.';
      } else if (!fileData.address_proof_link.type.startsWith('image/')) {
        newErrors.address_proof_link = 'Please upload a valid image file for Address Proof.';
      } else if (fileData.address_proof_link.size > MAX_FILE_SIZE) {
        newErrors.address_proof_link =
          'File size exceeds the maximum allowed size (500KB) for Address Proof.';
      }
    }

    // Validate File Inputs
    // if (!fileData.additional_doc_link) {
    //   newErrors.additional_doc_link = 'Additional Document is required.';
    // } else if (!fileData.additional_doc_link.type.startsWith('image/')) {
    //   newErrors.additional_doc_link = 'Please upload a valid image file for Additional Document.';
    // } else if (fileData.additional_doc_link.size > MAX_FILE_SIZE) {
    //   newErrors.additional_doc_link =
    //     'File size exceeds the maximum allowed size (500KB) for Additional Document.';
    // }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      // If validation fails, don't submit the form
      return;
    }

    const formDataToSend = new FormData();

    // Append form data
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    // Append file data
    for (const key in fileData) {
      formDataToSend.append(key, fileData[key]);
    }
    var url = apiAdmin.executiveAdd;

    if (id)
      url = `${apiAdmin.executiveEdit}/${id}`;

    networkRequest(url, (resp) => {
      Swal.fire({
        title: 'success',
        text: resp['message'],
        icon: 'success',
      });
      return navigate(-1);
    }, isLoading, 'post', formDataToSend);


  };

  const handleCityChange = (selectedCity) => {
    setSelectedCity(selectedCity);
    setFormData({ ...formData, city_id: selectedCity.value });
  };

  const fetchCityData = () => {
    networkRequest(apiAdmin.citiesGet, (data) => {
      const modifiedCityOptions = data['data'].map(city => ({
        value: city.id,
        label: `${city.name}-${city.state}`,
      }));
      setCityOptions(modifiedCityOptions);
    }, isLoading);
  };

  useEffect(() => {
    fetchCityData();
  }, []);

  useEffect(() => {
    if (id) {
      networkRequest(`${apiAdmin.executiveGetProfile}/${id}`, (data) => {
        var profile = data['data']['profile'];
        setFormData((prevFormData) => ({
          ...prevFormData,
          city_id: profile['city_id'],
          name: profile['name'],
          contact: profile['contact'],
          email: profile['email'],
          address: profile['address'],
          alternativeNumber: profile['alternativeNumber'],
          date_of_birth: profile['date_of_birth'],
          // Add other fields as needed
        }));

        setProfile({
          passport_size_photo: profile['passport_size_photo'],
          id_proof_link: profile['id_proof_link'],
          address_proof_link: profile['address_proof_link'],
          additional_doc_link: profile['additional_doc_link'],
        });
      }, isLoading);
    }
  }, [id, setCityOptions]);

  useEffect(()=>{
    const defaultSelectedCity = cityOptions.find(city => city.value === formData.city_id);
    setSelectedCity(defaultSelectedCity);
  },[formData.city_id]);




  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>Add Executive</h6>
              </div>
              <div className="col-6">
                <Link to={"/admin/executives"}> <button type="button" className="btn btn-primary has-icon float-right">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                </button></Link>
              </div>
            </div>
            <div className="ms-panel-body">
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  {/* Name */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationCustomName">Name</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        aria-describedby="inputGroupPrepend"
                        onChange={handleInputChange}

                      />
                      {errors.name && <div className="invalid-tooltip">{errors.name}</div>}
                    </div>
                  </div>

                  {/* Contact */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationCustomContact">Contact</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className={`form-control ${errors.contact ? 'is-invalid' : ''}`}
                        id="contact"
                        name="contact"
                        placeholder="Contact"
                        value={formData.contact}
                        aria-describedby="inputGroupPrepend"
                        onChange={handleInputChange}
                      />
                      {errors.contact && <div className="invalid-tooltip">{errors.contact}</div>}
                    </div>
                  </div>
                  {/* Email */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationCustomEmail">Email</label>
                    <div className="input-group">
                      <input
                        type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        aria-describedby="inputGroupPrepend"
                        onChange={handleInputChange}

                      />
                      {errors.email && <div className="invalid-tooltip">{errors.email}</div>}
                    </div>
                  </div>

                  {/* Address */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationCustomAddress">Address</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                        id="address"
                        name="address"
                        placeholder="Address"
                        value={formData.address}
                        aria-describedby="inputGroupPrepend"
                        onChange={handleInputChange}

                      />
                      {errors.address && <div className="invalid-tooltip">{errors.address}</div>}
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationCustomCity">City</label>
                    <Select
                      options={cityOptions}
                      onChange={handleCityChange}
                      value={selectedCity}
                      isSearchable
                      placeholder="Search for a city"
                    />
                  </div>

                  {/* Alternative Number */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationCustomAltNumber">Alternative Number</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className={`form-control ${errors.alternativeNumber ? 'is-invalid' : ''}`}
                        id="alternativeNumber"
                        name="alternativeNumber"
                        placeholder="Alternative Number"
                        value={formData.alternativeNumber}
                        aria-describedby="inputGroupPrepend"
                        onChange={handleInputChange}
                        min={5000000000}
                        max={9999999999}
                      />
                      {errors.alternativeNumber && (
                        <div className="invalid-tooltip">{errors.alternativeNumber}</div>
                      )}
                    </div>
                  </div>

                  {/* Date of Birth */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationCustomDOB">Date of Birth</label>
                    <div className="input-group">
                      <input
                        type="date"
                        className={`form-control ${errors.date_of_birth ? 'is-invalid' : ''}`}
                        id="date_of_birth"
                        name="date_of_birth"
                        placeholder="Date of Birth"
                        value={formData.date_of_birth}
                        aria-describedby="inputGroupPrepend"
                        onChange={handleInputChange}

                      />
                      {errors.date_of_birth && (
                        <div className="invalid-tooltip">{errors.date_of_birth}</div>
                      )}
                    </div>
                  </div>

                  {/* passport Size Photo */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationid_proof_link">Passport Size Photo {profile && profile.passport_size_photo && (<a target='_blank' rel="noreferrer" href={`${imageUrls.executive}${profile.passport_size_photo}`}><i class="fa fa-eye" aria-hidden="true"></i></a>)}</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className={`form-control ${errors.passport_size_photo ? 'is-invalid' : ''}`}
                        id="passport_size_photo"
                        name="passport_size_photo"
                        placeholder="passport Size Photo"
                        aria-describedby="inputGroupPrepend"
                        onChange={handleFileChange}

                      />
                      {errors.id_proof_link && (
                        <div className="invalid-tooltip">{errors.id_proof_link}</div>
                      )}
                    </div>
                  </div>

                  {/* ID Proof Document */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationid_proof_link">ID Proof Document {profile && profile.id_proof_link && (<a target='_blank' rel="noreferrer" href={`${imageUrls.executive}${profile.id_proof_link}`}><i class="fa fa-eye" aria-hidden="true"></i></a>)}</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className={`form-control ${errors.id_proof_link ? 'is-invalid' : ''}`}
                        id="id_proof_link"
                        name="id_proof_link"
                        placeholder="ID Proof Document"
                        aria-describedby="inputGroupPrepend"
                        onChange={handleFileChange}

                      />
                      {errors.id_proof_link && (
                        <div className="invalid-tooltip">{errors.id_proof_link}</div>
                      )}
                    </div>
                  </div>

                  {/* Address Proof Document 1 */}
                  <div className="col-md-4 mb-3">
                    <label htmlFor="validationCustomAddressProof1">Address Proof Document {profile && profile.address_proof_link && (<a target='_blank' rel="noreferrer" href={`${imageUrls.executive}${profile.address_proof_link}`}><i class="fa fa-eye" aria-hidden="true"></i></a>)}</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className={`form-control ${errors.address_proof_link ? 'is-invalid' : ''}`}
                        id="address_proof_link"
                        name="address_proof_link"
                        placeholder="Address Proof Document 1"
                        aria-describedby="inputGroupPrepend"
                        onChange={handleFileChange}

                      />
                      {errors.address_proof_link && (
                        <div className="invalid-tooltip">{errors.address_proof_link}</div>
                      )}
                    </div>
                  </div>

                  {/* Address Proof Document 2 */}
                  {/* <div className="col-md-4 mb-3">
                    <div>
                      {profile && profile.additional_doc_link && (
                        <img src={`${imageUrls.executive}${profile.additional_doc_link}`} alt="Additional Doc" width="100px" height="100px" />
                      )}
                    </div>
                    <label htmlFor="validationCustomAddressProof2">Address Proof Document 2</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className={`form-control ${errors.additional_doc_link ? 'is-invalid' : ''}`}
                        id="additional_doc_link"
                        name="additional_doc_link"
                        placeholder="Address Proof Document 2"
                        aria-describedby="inputGroupPrepend"
                        onChange={handleFileChange}

                      />
                      {errors.additional_doc_link && (
                        <div className="invalid-tooltip">{errors.additional_doc_link}</div>
                      )}
                    </div>
                  </div> */}

                </div>
                <button className="btn btn-primary" disabled={state.value} type="submit">
                  Submit form
                </button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
