const capitalizeFirstLetter = (str) => {
  const words = str.split(' ');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

const getMonthName = (month) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthNames[month - 1]; // Subtracting 1 because months are 0-indexed in JavaScript
}

const getLast12Months = () => {
  const months = [];
  const currentDate = new Date();
  let currentMonth = currentDate.getMonth() + 1; // Adding 1 to make it 1-indexed
  let currentYear = currentDate.getFullYear();

  for (let i = 0; i < 12; i++) {
    months.push({
      month: currentMonth,
      monthName: getMonthName(currentMonth),
      year: currentYear,
    });

    // Move to the previous month
    if (currentMonth === 1) {
      currentMonth = 12;
      currentYear--;
    } else {
      currentMonth--;
    }
  }

  return months.reverse(); // Reverse the array to start from the oldest month
}

const updateCounts = (existingMonths, newExecutives) => {
  const updatedMonths = existingMonths.map((existingMonth) => {
    const matchingExecutive = newExecutives.find(
      (executive) =>
        parseInt(executive.month) === existingMonth.month &&
        parseInt(executive.YEAR) === existingMonth.year
    );

    if (matchingExecutive) {
      return parseInt(matchingExecutive.count);
    } else {
      return 0;
    }
  });

  return updatedMonths;
}

const monthLabels = (monthsArray) => {
  return monthsArray.map(({ month, year, monthName }) => `${monthName} ${year}`);
}

function formatDate(dateString) {
  // Create a new Date object from the date string
  const date = new Date(dateString);

  // Extract the day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-based in JavaScript
  const year = date.getFullYear();

  // Pad single-digit day and month with a leading zero
  const formattedDay = day < 10 ? '0' + day : day;
  const formattedMonth = month < 10 ? '0' + month : month;

  // Return the formatted date string
  return `${formattedDay}-${formattedMonth}-${year}`;
}




export { capitalizeFirstLetter, getLast12Months, getMonthName, updateCounts, monthLabels, formatDate }