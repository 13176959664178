import React, { useEffect, useState } from 'react'
import coins_img from '../../assets/img/coins.png';
import { networkRequest } from '../../utils/network_request';
import { apiClient } from '../../utils/urls';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';


const PurchasePackage = () => {
    const navigate = useNavigate();
    const [packageList, setPackageList] = useState([]);
    const [executive, setExecutive] = useState({});
    const [coins, setCoins] = useState('');
    const { state, dispatch } = useGlobalState();
    const location = useLocation();
    const { name } = location.state;

    const isLoading = (state) => {
        dispatch({ type: 'SET_LOADING', payload: state });
    }

    const loadData = () => {
        networkRequest(apiClient.packagesGetClient, (data) => {
            setPackageList(data['data']['packages']);
            setExecutive(data['data']['executive']);
            setCoins(data['data']['coins']);
        }, isLoading);
    }

    useEffect(() => {
        loadData();
    }, []);  // Add 'loadData' to the dependency array


    const purchasePackages = (pkg) => {
        if (parseFloat(coins) >= parseFloat(pkg.sale_price)) {
            Swal.fire({
                icon: 'success',
                html: `Do you want to Purchase<br><strong>${pkg.title}</strong>,<br>coins<strong>${pkg.sale_price}</strong>?`,
                showCancelButton: true,
                confirmButtonText: 'Purchase',
                cancelButtonText: 'Close',
                preConfirm: () => {
                    Swal.showLoading();
                    const isLoading = (status) => dispatch({ type: 'SET_LOADING', payload: status });
                    return networkRequest(`${apiClient.postPurchasePackage}/${name}/${pkg.id}`, (data) => {
                        if (data.success) {
                            Swal.fire(data.message, '', 'success');
                            navigate(`/client/post-job-list`, { state: { name: data['data'] } });
                        } else {
                            Swal.fire(data.message, '', 'error');
                        }
                    }, isLoading);
                },
            });
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Balance is low',
                html: `Your balance is <strong>${coins}</strong> Coins. Please contact Executive <br/><strong>${executive['name']} <br/>${executive['contact']}</strong><br/>for assistance.`,
                confirmButtonText: 'Close',
            });
        }
    };


    return <div className="col-md-12 m-2">
        <div className="ms-panel p-2">
            <div className="row">
                {packageList.map((pkg) => (
                    <div key={pkg.id} className="col-md-4">
                        <div className="card">
                            <div className="card-header text-center">
                                <h3>{pkg.title}</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-3">
                                        <h3>
                                            <img src={coins_img} className='p-1' alt="coin" width={90} /></h3></div>

                                    <h5>
                                        <s> {pkg.cost_price} </s>/- <h5>{pkg.sale_price} /-</h5>
                                    </h5>
                                </div>
                                <p><i className="fa fa-check ml-5 text-success mr-1" aria-hidden="true"></i> {pkg.validity} Days Validity</p>
                                <p> <i className={`fa ${pkg.whatsapp === '1' ? 'fa-check text-success' : 'fa-times text-danger'} ml-5 mr-1`} aria-hidden="true"></i> Whatsapp Status</p>
                                <p> <i className={`fa ${pkg.story === '1' ? 'fa-check text-success' : 'fa-times text-danger'} ml-5 mr-2`} aria-hidden="true"></i>  In App Story</p>
                            </div>
                            <button type="button" onClick={() => purchasePackages(pkg)} className="btn btn-primary has-icon float-right">
                                Select Package
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div></div>

}

export default PurchasePackage;