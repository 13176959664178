/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { networkRequest } from '../../utils/network_request';
import { apiAdmin, apiGeneral } from '../../utils/urls';
import { useGlobalState } from '../../global_state/GlobalStateContext';
import Swal from 'sweetalert2';

const Cities = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [cities, setCities] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useGlobalState();
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    state: ''
  });

  const handleInputs = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormError({
      ...formError, [e.target.name]: e.target.value.length < 4 ? 'Input should be at least 4 characters long and non-numerical' : ''
    });
  };

  const isLoading = (status) => {
    dispatch({ type: 'SET_LOADING', payload: status });
  };  // Add 'dispatch' to the dependency array


  const loadData = () => {
    networkRequest(apiGeneral.citiesGet, setData, isLoading);
  };  // Add 'isLoading' to the dependency array


  useEffect(() => {
    loadData();
  }, []);  // Add 'loadData' to the dependency array

  const setData = (data) => {
    console.log(data);
    setCities(data['data']);
  }

  const deleteCities = (cities) => {
    Swal.fire({
      icon: 'warning',
      text: `Do you want to delete Job Category ${cities.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Close',
      preConfirm: () => {
        Swal.showLoading();
        const isLoading = (status) => dispatch({ type: 'SET_LOADING', payload: status });
        return networkRequest(`${apiAdmin.jobCategoriesDelete}/${cities.id}`, '', isLoading)
          .then(() => {
            Swal.fire('Job Category deleted successfully!', '', 'success');
            // Update the executive list after successful deletion
            setCities(prevList => prevList.filter(item => item.id !== cities.id));
          })
          .catch(() => Swal.fire('Error deleting Job Category. Please try again.', '', 'error'));
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formError.name !== "")
      return;

    if (formError.state !== "")
      return;

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    networkRequest(apiAdmin.cityAdd, (data) => {
      setShowRegisterModal(false);
      Swal.fire({
        title: "success",
        text: data['message'],
        icon: 'success',
        type: 'success'
      });
      loadData();
    }, isLoading, 'post', formDataToSend);
  }


  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-xl-12">
          <div className="ms-panel">
            <div className="ms-panel-header row">
              <div className="col-6">
                <h6>Cities List</h6>
              </div>
              <div className="col-6">
                <button type="button" className="btn btn-primary has-icon float-right" onClick={() => {
                  setFormData(
                    {
                      ...formData,
                      id: '',
                      name: '',
                      state: ''
                    }
                  )
                  setShowRegisterModal(true)
                }}>
                  <i className="fa fa-plus" aria-hidden="true"></i> Add Cities
                </button>
              </div>
            </div>
            <div className="ms-panel-body">
              <div className="table-responsive">
                <table className="table table-hover thead-primary">
                  <thead>
                    <tr>
                      <th scope="col">City ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">State</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cities.map((city) => (
                      <tr key={city.id}>
                        <th scope="row">{city.id.padStart(3,'0')}</th>
                        <td>{city.name}</td>
                        <td>{city.state}</td>
                        <td>
                          <span onClick={() => {
                            setFormData({
                              ...formData,
                              name: city.name,
                              state: city.state,
                              id: city.id
                            });
                            setShowRegisterModal(true)
                          }}><i className="fas fa-pencil-alt text-secondary" /></span>
                          <Link to="#" onClick={() => deleteCities(city)}>
                            <i className="far fa-trash-alt ms-text-danger" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showRegisterModal} aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="bg-primary">
          <h3 className="modal-title has-icon ms-icon-round text-white"><i className="fa fa-building bg-primary text-white" /> Cities</h3>
        </Modal.Header>
        <form method="post" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="ms-form-group has-icon">
              <label htmlFor="name">Name Of State</label>
              <input type="text" placeholder="Maharashtra, Kerla" className="form-control" id="state" name="state" value={formData.state} onChange={handleInputs} />
              <p className='text-danger text-right mr-3'>{formError.state && (formError.state)}</p>
            </div>
            <div className="ms-form-group has-icon">
              <label htmlFor="name">Name Of Cities</label>
              <input type="text" placeholder="Mumbai, Delhi" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputs} />
              <p className='text-danger text-right mr-3'>{formError.name && (formError.name)}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={() => setShowRegisterModal(false)}>Cancel</button>
            <button type="submit" className="btn btn-primary shadow-none">Submit</button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default Cities
